<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">
        <!-- SLIDER START -->
        <app-section-slider1></app-section-slider1>
        <!-- SLIDER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about2></app-section-about2>
        <!-- ABOUT SECTION  SECTION END -->

        <!-- SERVICES SECTION START -->
        <app-section-services6></app-section-services6>
        <!-- SERVICES SECTION END -->

        <!-- OUR PROJECTS START -->
        <app-section-projects1></app-section-projects1>
        <!-- OUR PROJECTS END -->

        <!-- QUALITY SECTION START -->
        <app-section-quality1></app-section-quality1>
        <!-- QUALITY SECTION END -->

        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>