import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eficienta-energetica',
  templateUrl: './eficienta-energetica.component.html',
  styleUrls: ['./eficienta-energetica.component.css']
})
export class EficientaEnergeticaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "EFICIENȚĂ ENERGETICĂ",
  }

}
