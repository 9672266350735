<div class="section-full  p-t80 p-b50  bg-gray-light">
    <div class="container">
    
        <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
                <div class="col-lg-6 col-md-7 wt-separator-two-part-left">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                    </div>
                    <!-- TITLE END-->
                </div>
                <div class="col-lg-6 col-md-5 wt-separator-two-part-right text-right">
                    <a routerLink="/blog/blog-grid" class="site-button site-btn-effect">More Detail</a>
                </div>
            </div>
        </div>

        <!-- BLOG SECTION START -->
        <div class="section-content">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let object of data.articles">
                    <!--Block one-->
                    <div class="latest-blog-3-pattern">
                        <div class="blog-post latest-blog-3 overlay-wraper post-overlay  large-date bg-cover bg-no-repeat bg-top-center" style="background-image:url(assets/images/ui-9/latest-blog/bg-1.jpg);">
                            <div class="overlay-main opacity-05 bg-black"></div>
                                <div class="wt-post-info p-a30 text-white">
                                    <div class="post-overlay-position">
                                        <div class="post-content-outer">
                                            <div class="wt-post-meta ">
                                                <ul>
                                                    <li class="post-date"><strong>{{object.date}}</strong><span>{{object.mmyy}}</span></li>
                                                    <li class="post-author"><i class="fa fa-user-o"></i>By <a routerLink="/other/team-single">{{object.author}}</a> </li>
                                                    <li class="post-comment"><i class="fa fa-comments-o"></i> <a routerLink="/other/team-single">{{object.comments}} comment</a> </li>
                                                </ul>
                                            </div>                                    
                                            <div class="wt-post-title ">
                                                <h3 class="post-title"><a routerLink="/blog/blog-post-right-sidebar" class="text-white text-capitalize">{{object.title}}</a></h3>
                                            </div>
                                            <div class="wt-post-readmore ">
                                                <a routerLink="/blog/blog-grid" class="site-button-link white">Read More</a>
                                            </div>
                                        </div>  
                        
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>                           
                                            
            </div>
        </div>
    </div>
    
 </div>