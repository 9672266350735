import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header5',
  templateUrl: './section-header5.component.html',
  styleUrls: ['./section-header5.component.css']
})
export class SectionHeader5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
