<div class="section-full p-t80 p-b50">

    <div class="container">
    
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->                        
            
        <div class="section-content hover-block-outer">
                <div class="row d-flex justify-content-center">
                
                    <div class="col-md-7 col-sm-12 m-b30 top-bottom-animation ">
                        <div class="Safe-mid-section">
                            <div class="wt-box">
                                <div class="wt-media text-primary text-center">
                                    <img src="{{data.image}}" alt="" class="img-top-bottom-aanimation">
                                </div>
                            </div>
                         </div>  
                    </div>                                
                
                    <div class="col-md-5 col-sm-12 m-b30  p-t30">
                        <div class="wt-icon-box-wraper left p-a20" *ngFor="let object of data.specifications">
                            <div class="icon-xl text-primary">
                                <span class="icon-cell"><img src="{{object.image}}" alt=""></span>
                            </div>
                            <div class="icon-content">
                                <h3 class="wt-tilte text-uppercase">{{object.title}}</h3>
                                <p>{{object.description}} </p>
                            </div>
                        </div>   
                    </div>
                    
                </div>
        </div>                          

</div>
</div>