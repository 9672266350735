<div class="section-full  p-t80 p-b50 bg-no-repeat bg-center bg-white" style="background-image:url(assets/images/background/bg-map.png)">
    <div class="container">

        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->
        
        <div class="testimonial-slider-two">
            <div class="testimonial-carousel-5 owl-carousel owl-theme owl-btn-bottom-center">
            
                <div class="item" *ngFor="let object of data.quotes">
                    <div class="testimonial-slider-content clearfix">
                        <div class="testimonial-5 clearfix">
                            <div class="testimonial-text shadow-sm">

                                <div class="testimonial-paragraph">
                                    <div class="quote-left"></div>
                                    <p>{{object.quote}}</p>
                                    
                                </div>
                                <div class="testimonial-detail ">
                                    <h4 class="testimonial-name">{{object.name}}</h4>
                                </div>
                                <div class="testimonial-detail ">
                                    <span class="testimonial-position">{{object.specification}}</span>
                                </div>
                            </div>
                            <div class="testimonial-pic-block"> 
                                <div class="testimonial-pic">
                                    <img src="{{object.image}}" alt="">
                                </div>
                            </div>
                        </div>                                    
                    </div>
                </div>
                
            </div>
       </div>   

    </div>
</div>