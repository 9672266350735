import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services/services.service';

@Component({
  selector: 'app-page-home1',
  templateUrl: './page-home1.component.html',
  styleUrls: ['./page-home1.component.css']
})
export class PageHome1Component implements OnInit {

  ngOnInit(): void {
  }
}
