<div class="section-full  p-t80 p-b80 blog-gallery-block-wrap  overflow-hide bg-white">
    <div class="blog-gallery-block-outer">



        <div class="container">

            <div class="wt-separator-two-part">
                <div class="row wt-separator-two-part-row">
                    <div class="col-lg-6 col-md-12 wt-separator-two-part-left">
                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer when-bg-dark">
                            <div class="wt-small-separator site-text-primary">
                                <div>{{data.title}}
                                </div>
                            </div>
                            <h2 class="wt-title">{{data.subitle}}</h2>
                        </div>
                        <!-- TITLE END-->
                    </div>
                                                
                </div>
            </div>

            <div class="section-content">
                   
                <div class="blog-gallery-style1">
                    <div class="owl-carousel blog-gallery-one owl-btn-vertical-center ">
                    
                        <!-- COLUMNS 1 -->  
                        <div class="item" *ngFor="let object of data.blogs">
                            <div class="blog-post latest-blog-11  block-shadow">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a routerLink="/blog/blog-post-right-sidebar"><img src="{{object.image}}" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date">{{object.date}}</li>
                                        </ul>
                                    </div>                                    
                                    <div class="wt-post-title ">
                                        <h4 class="post-title">{{object.title}}</h4>
                                    </div>
                                    <div class="wt-post-readmore">
                                        <a routerLink="/blog/blog-grid" class="site-button-link site-text-primary">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>                                             

                    </div>
                </div>
                
            </div>                              
        </div>
    </div>
</div>