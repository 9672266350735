import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-clients1',
  templateUrl: './section-clients1.component.html',
  styleUrls: ['./section-clients1.component.css']
})
export class SectionClients1Component implements OnInit {


  data = {
    bgcolor: "bg-gray",
    clients: [
      "assets/images/logos/electroglobal.svg",
      "assets/images/logos/safesteel.png",
      "assets/images/logos/climaterm.png",
      "assets/images/logos/nova.png",
      "assets/images/logos/estimo.png",
      "assets/images/logos/electrogrup.png",
      "assets/images/logos/nordconforest.png",
      "assets/images/logos/lumii.png",
      "assets/images/logos/legrand.png",
      "assets/images/logos/schneider.png",
      "assets/images/logos/schrack.png",
      "assets/images/logos/polon.png",
      "assets/images/logos/siemens.png",
      "assets/images/logos/fronius.png",
      "assets/images/logos/huawei.svg",
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

}
