<div class="page-wraper">
    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->
  
    <!-- CONTENT START -->
    <div class="page-content">
      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- FAQ SECTION START -->
      <div class="section-full p-t20 p-b40 bg-white">
        <div class="container">
          <div class="section-content">
            <div class="container">
              <div class="row d-flex justify-content-center flex-wrap ">
                <div class="col-lg-12 col-md-12 m-b30 text-justify">
                  <p class="indent-paragraph">
                    <b>Instalațiile electrice de medie tensiune</b> sunt acele instalații care funcționează la tensiune cuprinse între 1 – 20 kV. Acestea sunt deobicei tensiuni de 1kV, 6kV, 10kV sau 20 kV. În funcție de modul în care se realizează aceste instalații acestea pot fi cuprinse în două categorii: linii electrice aeriene sau subterane.
                  </p>
                  <ul class="list-check-circle primary">
                    <li>
                      Instalații electrice interioare, care cuprind conductoarele
                      cu toate elementele accesorii de montaj, întrerupere,
                      protecții ce se monteaza în interiorul clădirilor de orice
                      fel;
                    </li>
                    <li>
                      Instalații electrice exterioare, la care elementele
                      instalației sunt montate în afara clădirilor.
                    </li>
                  </ul>
                  <p>
                    <b>
                      Din punct de vedere al intensității curentului și al
                      destinaţiei (receptoarele alimentate), instalaţiile electrice
                      pe care le putem executa sunt:</b>
                  </p>
                  <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                    <div class="panel wt-panel">
                      <div class="accord-head acc-actives">
                        <h3 class="acod-title">
                          <a data-toggle="collapse" data-parent="#accordion5" class="p-r70">
                            1. Liniile electrice aeriene de medie tensiune (LEA MT)
                            <span class="indicator"><i class="fa fa-star"></i></span>
                          </a>
                        </h3>
                      </div>
                      <div class="acod-body show">
                        <ul class="acod-content p-l40 p-r70 p-t5 p-b20 bg-gray">
                          <li>
                            Se realizează cu stâlpi de beton de tip vibrați centrifugați sau precomprimați, pe care se montează accesoriile de susținere a conductoarelor electrice (console, izolatori și alte elemente).
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="panel wt-panel">
                      <div class="accord-head acc-actives">
                        <h3 class="acod-title">
                          <a data-toggle="collapse" data-parent="#accordion5">
                            2. Liniile electrice subterane de medie tensiune (LES MT)
                            <span class="indicator"><i class="fa fa-star"></i></span>
                          </a>
                        </h3>
                      </div>
                      <div class="acod-body show">
                        <ul class="acod-content p-l40 p-r70 p-t5 p-b20 bg-gray">
                          <li>Se realizează prin pozarea subterană a cablurilor electrice de medie tensiune.
                            Există situații în care cablurile electrice de medie tensiune sunt pozate aparent, pe stelaje metalice sau pe alte accesorii.
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h3>Posturile de transformare</h3>
                  <p>
                    Acestea sunt o componentă foarte importantă a instalațiilor electrice de medie tensiune. Prin acestea se realizează tranziția de la nivelul de medie tensiune, în cel de joasă tensiune, utilizat de majoritatea receptoarelor de energie electrică.
                    <b>
                      În funcție de modalitatea în care sunt montate și amplasate, pot fi:</b>
                  </p>
                  <ul class="list-check-circle primary">
                    <li>
                      de tip aerian (PTA),
                    </li>
                    <li>
                      subteran (PTS) sau în cabină zidită (PTZ),
                    </li>
                    <li>
                      respectiv în anvelopă de beton sau de metal (PTAB).
                    </li>
                  </ul>

                  <p>Părțile componente ale posturilor de transformare sunt celulele de medie tensiune – <b>6kV , 10 sau 20kV</b>, transformatoarele și respectiv tablourile cu aparatajul de joasă tensiune – <b>0,4kV</b>. O importanță deosebită în construcția instalațiilor de medie tensiune, în construcție aeriană sau subterană o reprezintă realizarea accesoriilor, respectiv racordurile la aparataje (capetele terminale de medie tensiune), manșoanele pentru situațiile speciale respectiv conexiunile.</p>
                  <h3>Branșamente de Medie Tensiune</h3>
                  <p>Branșamentul electric reprezintă instalația care realizează legătura între rețeaua de distribuție a furnizorului și cea a consumatorului. Branșamentele se proiectează și se execută doar în baza dosarelor preliminare depuse la întreprinderea furnizoare de energie electrică și aprobate de aceasta. După efectuarea studiilor și măsurătorilor pe teren, întreprinderea furnizoare întocmește devizele și notele de lucrări în vederea executării branșamentului. În procesul de branșare, pe lângă etapa întocmirii dosarului necesar pentru furnizarea energiei electrice, ne ocupam și de faza de execuție a lucrării.</p>
                  <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                    <div class="panel wt-panel">
                      <div class="accord-head acc-actives">
                        <h3 class="acod-title">
                          <a data-toggle="collapse" data-parent="#accordion5">
                            De regulă, o lucrare de branșament conține următoarele etape:
                            <span class="indicator"><i class="fa fa-star"></i></span>
                          </a>
                        </h3>
                      </div>
                      <div class="acod-body show">
                        <ul class="acod-content p-l40 p-r70 p-t5 p-b20 bg-gray">
                          <li> Preluarea dosarului preliminar</li>
                          <li> Depunerea cererii de racordare și a documentelor necesare</li>
                          <li> Stabilirea soluției de racordare la rețea</li>
                          <li> Emiterea Avizului Tehnic de Racordare</li>
                          <li> Solicitarea contractului de racordare și alegerea operatorului care va proiecta/executa instalația electrică</li>
                          <li> Proiectarea și execuția lucrărilor de racordare</li>
                          <li> Punerea în funcțiune a instalației de racordare</li>
                          <li> Depunerea Dosarului Instalației de Utilizare</li>
                          <li> Emiterea Certificatului de Racordare</li>
                          <li> Punerea sub tensiune finală și regularizare</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>Rețelele electrice de medie tensiune se folosesc în centrele urbane, industriale și rurale pentru alimentarea unuia sau mai multor posturi de transformare sau a receptorilor de medie tensiune. În achiziționarea unui post de transformare este necesară urmărirea aspectelor generale care indică calitatea acestuia. Astfel, ne referim la construcția modulară, la siguranța personalului de exploatare, durata de viață, ușurința montajului, dar și suprafața ocupată la sol.</p>
                  <h3>PTAB – (Post de transformare în anvelopă de beton)</h3>
                  <p>Posturile  de transformare (numite în continuare PT ) MT/JT sunt parte componentă a rețelelor de distribuție RED prin care se  asigura modificarea nivelului tensiunii energiei electrice de la MT (20 si 6 kV) la 0.4 kV. PT  este compus din echipamente de conexiuni de MT, unul sau mai multe transformatoare MT/JT și tablouri de distribuţie JT precum și construcția/incinta care adăposteste/susține echipamentul electric. Unele PT modernizate sunt incluse în sistemul de automatizare a distribuției (SAD) fiind dotate cu telecomenzi.</p>
                  <h4><b>Acestea pot fi clasificate în mai multe feluri:</b></h4>
                  <h4> ~ După destinație:</h4>
                  <p>PT de distribuţie publică – alimenteaza cu energie electrica (ee) rețele stradale destinate  racordării consumatorilor din zonele rezidentiale urbane/rurale <br />
                    PT de servicii interne ale instalațiilor  Operator Distributie  de ex. staţii de transformare (110kV /MT) sau a altor operatori din Sistemul Energetic National (SEN). <br />
                    PT de alimentare individuala a agenților economici (și/sau clienti casnici) sau a grupurilor mici de agenți economici aflate, după caz, în gestiunea terților sau a  Operatorulor de Distribuție .</p>
                  <h4> ~ După tipul modului de acces: </h4>
                  <p>PT în anvelopă (de beton sau metalică) cu  acces în interior pentru manevre/lucrări. <br />
                    PT în anvelopă (de beton sau metalică) sau PT la sol cu deservire exterioară. Echipamentele de MT și JT sunt acționate de la sol, din exteriorul PT-ului prin deschiderea de uși de vizitare.
                  </p>
                  <h4> ~ După soluția tehnică de realizare a PT :</h4>
                  PTA aerian (PTA) Se montează pe unu sau doi stâlpi. Este racordat de regula la linii aeriene MT cu conectare cu conductoare neizolate, câteodată şi conductoare preizolate sau cabluri de MT torsadate. În mod excepţional este posibil ca PT  de stâlpi sa fie racordat la LEA MT cu cablu subteran. 
                    <br />
                    <br />
                    <div class="m-l20">
                      <b>PT  pe stâlpi se împart în:</b>
                      <ul class="sublist">
                        <li>PT  pe un stâlp de beton cu putere instalata Sn 16- 400 kVA.</li>
                        <li>PT  doi stalpi de beton cu putere instalata Sn 400-630 kVA</li>
                      </ul>
                      <b>PT cabină – acesta poate fi:</b>
                      <ul class="sublist">
                        <li>PT în cabină zidită, PTCZ care poate fi independentă sau inglobată într-o clădire civilă (de regulă un bloc de locuințe) sau industrială de beton. Se echipează de regulă cu unul sau mai multe transformatoare cu puteri 40-1600 kVA fiind destinate să alimenteze consumatori rezidențiali și/sau industiali. PTCZ este integrat intr-o retea LES având două sau mai multe celule de linie sau este racordat radial in LES. Există și un număr redus de cazuri în care PTCZ este racordat în soluție aeriană la LEA MT. Tot echipamentul electric de MT și JT este montat în interiorul PTCZ. Manevrele și lucrările se fac cu accesul personalului în interiorul PTCZ.</li>
                        <li>PT în anvelopă de beton sau metalică (PTAB, PTAM). Sunt posturi moderne, compacte care pot fi executate în două variante constructive cu sau fără acces în interiorul postului pentru manevre/lucrari. Pot fi echipate cu unul sau două transformatoare până la 1000 kVA (de regula sunt echipate cu un transformator) care se pot schimba numai cu demontarea prealabila a acoperisului PTAB/PTAM</li>
                        <li>Pentru puteri mari de 1000-1600 (sau mai mari) kVA transformatoarele PTAB/PTAM se pot amplasa și în exterior în spații ingrădite cu gard de plasa de sârmă</li>
                      </ul>
                    </div>
                    Toate PTAB/ PTAM sunt racordate la RED prin cablu de MT respectiv de JT. Ele au la bază o cuvă de beton impermeabil la apă iar  cablurile de racordare MT și JT se fac în soluție etanșă utilizând diafragme special proiectate. În compartimentul transformatorului cuva are și rolul de a reține uleiul care s-ar putea scurge din transformator pentru prevenirea poluării solului. 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ SECTION END -->
    </div>
    <!-- CONTENT END -->
  
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
  </div>
  
  <app-loader></app-loader>