<div class="section-full  p-t80 p-b80 bg-gray-light">
    <div class="container">

        <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
                <div class="col-lg-8 col-md-7 wt-separator-two-part-left">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                    </div>
                    <!-- TITLE END-->
                </div>
                <div class="col-lg-4 col-md-5 wt-separator-two-part-right text-right">
                    <a routerLink="/projects/project-grid" class="site-button site-btn-effect">More Detail</a>
                </div>
            </div>
        </div>

    </div> 
        
    <div class="section-content">
        
        <div class="container-fluid">	
            <div class="projects-slider-two">
                <div class="owl-carousel projects-carousel-two owl-btn-vertical-center">
                    <!-- COLUMNS 1 -->
                    <div class="item" *ngFor="let object of data.projects">
                        <div class="projects-two-info bg-white">
                            <div class="wt-media img-reflection">
                                <img src="{{object.image}}" alt="">
                            </div>
                            <h3 class="wt-tilte m-t0" data-title="{{object.count}}"><a routerLink="/projects/project-detail">Automated Machine Systems</a></h3>
                        </div>
                    </div>                                                                                            
                                                                                                                                                    
                </div>                        
            </div>
        </div>
    </div>

</div>