<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header8></app-section-header8>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START -->
        <app-section-slider12></app-section-slider12>
        <!-- SLIDER END -->

        <!-- ABOUT ONE SECTION START -->
        <app-section-about8 [data]="about"></app-section-about8>
        <!-- ABOUT ONE SECTION END -->

        <!-- INTRO SECTION START -->
        <app-section-features1 [data]="features"></app-section-features1>
        <!-- INTRO SECTION END -->

        <!-- SERVICES SECTION START -->
        <app-section-services10 [data]="services"></app-section-services10>
        <!-- SERVICES SECTION END -->

        <!-- CALL US SECTION START -->
        <app-section-call-us2></app-section-call-us2>
        <!-- CALL US SECTION END -->

        <!-- CONTACT US START -->
        <app-section-contact-us1></app-section-contact-us1>
        <!-- CONTACT US SECTION END -->

        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials7 [data]="testimonials"></app-section-testimonials7>
        <!-- TESTIMONIAL SECTION END -->

        <!-- OUR BLOG START -->
        <app-section-blogs10 [data]="blogs" wide="0"></app-section-blogs10>
        <!-- OUR BLOG END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer3></app-section-footer3>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>