import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  all: JobDescription[] = [
    new JobDescription({
      imageName: "4.png",
      isActive: true,
      name: "AREA SALES MANAGER (inginer vanzari)",
      criterii_selectie: "Peste 1 an experiență în Vanzari, Inginerie, Administrativ / Logistica, Achizitii, Constructii / Instalatii;",
      canditatul_ideal: [
        "Studii superioare de specialitate (preferabil Facultatea de Electrotehnica/Energetica);",
        "Experienta minim 1 an in domeniul vanzarilor/ofertarii de echipament, servicii, de preferat servicii energetice;",
        "Cunoastere echipamente electrice si de automatizare, solutii de eficientizare energetica, servicii de audit energetic;",
        "Cunostinte de limba engleza - nivel mediu plus;",
        "Cunostinte de operare PC, MS Office, Research Google, comunicare;",
        "Comunicativ, dinamic, organizat, responsabil, productiv, autonomy;",
      ],
      descrierea_jobului: [
        "Realizarea ofertelor de echipamente si servicii de audit energetic;",
        "Promovarea produselor companiei pe piata prin transmiterea informatiilor pe email/telefon/personal;",
        "Transmiterea operativa si urmarirea ofertelor, contractelor si implementarilor.",
        "Intocmirea documentelor comerciale pentru vanzari;",
        "Asigura suport tehnic in activitatea comerciala desfasurata de managerii de vanzari si supravegheaza implementarea proiectelor;",
      ],
      se_cere: [
        "Realizarea ofertelor de materiale, echipamente si servicii;",
        "Promovarea produselor companiei pe piata prin transmiterea informatiilor pe email/telefon/personal;",
        "Transmiterea operativa si urmarirea ofertelor;",
        "Intocmirea documentelor comerciale pentru vanzari;",
        "Asigura suport tehnic in activitatea comerciala desfasurata de managerii de vanzari;",
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice",
        "Asistenta in invatarea si insusirea programelor de lucru specific",
        "Comision din vanzari",
        "Masina de serviciu;"
      ]
    }),
    new JobDescription({
      imageName: "5.png",
      isActive: true,
      name: "INGINER OFERTARE (back-office)",
      criterii_selectie: "Peste 1 an experiență în Constructii / Instalatii, Inginerie, Proiectare civila / industriala;",
      canditatul_ideal: [
        "Studii superioare de specialitate (preferabil Facultatea de Electrotehnica/Energetica);",
        "Experienta minim 1 an in domeniul vanzarilor/ofertarii materiale electrice;",
        "Cunoastere echipamente electrice si de automatizare;",
        "Cunostinte de limba engleza - nivel mediu;",
        "Cunostinte de operare PC, MS Office;",
        "Comunicativ, dinamic, organizat, responsabil, proactive;",
      ],
      descrierea_jobului: [
        "Elaborarea documentației tehnice aferentă instalațiilor pentru construcții specialitatea electrice;",
        "Atingerea obiectivelor de dezvoltarea a departamentului, în concordanță cu obiectivele de dezvoltare ale firmei;",
        "Ridicarea în permanență a nivelului calității activității de proiectare desfașurată în cadrul firmei;",
        "Preocuparea în permanență pentru respectarea SMC de către propria persoană;"
      ],
      se_cere: [
        "Realizarea ofertelor de materiale, echipamente si servicii;",
        "Promovarea produselor companiei pe piata prin transmiterea informatiilor pe email/telefon/personal;",
        "Transmiterea operativa si urmarirea ofertelor;",
        "Intocmirea documentelor comerciale pentru vanzari;",
        "Asigura suport tehnic in activitatea comerciala desfasurata de managerii de vanzari;"
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice;",
        "Asistenta in invatarea si insusirea programelor de lucru specific;",
        "Masina de serviciu;"
      ]
    }),
    new JobDescription({
      isActive: true,
      imageName: "5.png",
      name: "INGINER PROIECTANT INSTALATII ELECTRICE",
      criterii_selectie: "Peste 1 an experiență în Constructii / Instalatii, Inginerie, Proiectare civila / industriala;",
      canditatul_ideal: [
        "Absolvent al Facultatii de Electrotehnica sau Instalatii;",
        "Minim 1 an experienta in proiectare instalatii electrice diverse;"
      ],
      descrierea_jobului: [
        "Elaborarea documentației tehnice aferentă instalațiilor pentru construcții, industrial, energetic, specialitatea electrice;",
        "Atingerea obiectivelor de dezvoltarea a departamentului, în concordanță cu obiectivele de dezvoltare ale firmei;",
        "Ridicarea în permanență a nivelului calității activității de proiectare desfașurată în cadrul firmei;",
        "Preocuparea în permanență pentru respectarea SMC de către propria persoană;",
        "Proiectare de : sisteme fotovoltaice, sisteme de securitate, sisteme de antiincendiu, instalatii de curenți tari și slabi, aferente domeniului civil, industrial si energetic."
      ],
      se_cere: [
        "Dorinţa de a lucra în activitatea de inginerie, concepţie-proiectare electrica, implementare;",
        "Abilităţi de comunicare şi disponibilitate la lucru în echipă;",
        "Capacitate de a lucra fără îndrumare;",
        "Cunostinte adecvate a toolurilor de proiectare: AutoCad, NanoCad, Microsoft Office;",
        "Spirit de iniţiativă şi gândire practică;",
        "Receptivitate la nou şi adaptare la schimbare;",
        "Modestie şi atitudine corectă;",
        "Atenție la detalii;"
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice;",
        "Asistenta in invatarea si insusirea programelor de lucru specifice;",
        "Masina se serviciu;"
      ]
    }),
    new JobDescription({
      isActive: true,
      imageName: "5.png",
      name: "INGINER RESPONSABIL AUDIT SI EFICIENTA ENERGETICA",
      criterii_selectie: ["Minim 1 an experiență în Constructii / Instalatii / Inginerie / Proiectare / Energetica;", "Integru;"],
      canditatul_ideal: [
        "Absolvent al Facultatii de Electrotehnica / Instalatii / Inginerie / Energetica;",
        "Minim 1 an experienta intr-un post similar;",
        "Minim 1 an experienta in proiectare instalatii electrice;",
        "Disponibil relocarilor scurte;",
      ],
      descrierea_jobului: [
        "Executie lucrari de audit energetic la instalatii electrice/energetice de joasa tensiune pentru constructii civile si industrial;",
        "Executie si implementarea de masurii de eficienta energetica pentru instalatii energetice;",
        "Atingerea obiectivelor de dezvoltarea a departamentului, în concordanță cu obiectivele de dezvoltare ale firmei;",
        "Ridicarea în permanență a nivelului calității activității de audit si eficienta energetica desfașurată în cadrul firmei;",
        "Preocuparea în permanență pentru respectarea SMC de către propria persoană;",
      ],
      se_cere: [
        "Persoana disponibila sa invete lucruri noi si provocatoare;",
        "Persoana agera si isteata;",
        "Persoana care urmareste constant sa isi tina cuvantul si sa creasca profesional;",
        "Persoana vesela si sociabila;",
        "Persoana care cunoaste aspecte generale tehnice de instalatii civile, industriale si energetice de curenți tari și slabi, sisteme fotovoltaice;",
        "Persoana care poate mentine frumos si placut relatiile cu clienti si angajati;",
        "Capacitate de optimizare, analiza si sinteza;",
        "Cunoaste engleza la nivel intermediar +;",
        "Posesor permis conducere tip B;",
        "Experienta si abilitati de preluare a sarcinilor, planificare, de coordonare si integrare echipe/muncitorii in santiere;",
        "Experienta si cunostinte in citire planuri, liste, scheme diverse;",
        "Experienta si cunostinte in intocmirea necesarelor de materiale si urmarirea consumurilor de materiale pe lucrari; ",
        "Experienta si cunostinta in folosire, urmarirea si gestionarea responsabila a sculelor si utilajelor;",
        "Experienta si cunostinte in alocarea si urmarirea sarcinilor date la muncitori;",
        "Experienta si cunostinte montaj TD, legaturi in T D, echipamente din TE, citire scheme monofilare;",
        "Experienta si cunoștințe de cablaj curenți tari și slabi;",
        "Experienta si cunoștințe de montaj jgheaburi, corpuri iluminat, iluminat panica, aparataje, senzori, montaje mecanice și ansamblari;",
        "Experienta si cunostinte de montaje si conectare-racordare: FDCP-uri, tablouri electrice, tablouri electrice diverse. ",
        "Experienta si cunoștințe despre sisteme fotovoltaice, punerea lor în funcțiune, remedierea defectelor, întreținerea lor, constituie avantaj;",
        "Experienta si cunoastere utilizare PC si aplicati diverse (nivel intermediar +) si pachetul MicroSoft Office( word, excel), nivel intermediar, constituie avantaj dar nu conditia eliminatorie;",
        "Urmarirea si gestionarea proiectelor si lucrarilor de audit si a solutiilor de eficienta energetica, sisteme fotovoltaice,",
        "Realizeaza rapoarte zilnice / saptamanale / lunare;",
        "Realizeaza verificari calitative si cantitative in santiere;",
        "Realizeaza PIF-uri la echipamente si instalatii de curenți tari și slabi;",
        "Urmareste diverse aspecte ce tin de realizarea instalatiilor energetice in santier,  cereri de oferta la furnizori, ofertare catre client, livrare de materiale in santier, urmarire consumurilor, urmarirea manoperei realizate, situatii de lucrari, pontaje, urmarirea sculelor, suport tehnic pentru clienti si angajati;",
        "Realizeaza survey-uri comerciale si tehnice la client;",
        "Tine legatura cu clientii si cu partenerii din Anglia;",
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice;",
        "Asistenta in invatarea si insusirea programelor de lucru specific;",
        "Masina de serviciu;",
      ]
    }),
    new JobDescription({
      imageName: '2.png',
      isActive: true,
      name: "INGINER RESPONSABIL LUCRARI INSTALATII",
      criterii_selectie: ["Minim 1 an experiență în Constructii / Instalatii / Inginerie / Proiectare / Energetica;", "Integru;"],
      canditatul_ideal: [
        "Absolvent al Facultatii de Electrotehnica / Instalatii / Inginerie / Energetica;",
        "Minim 1 an experienta intr-un post similar;"
      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii electrice interioare si exterioare de joasa tensiune pentru constructii civile si industrial, bransamente, curenti tari, curenti slabi; ",
        "Executie de sisteme: sisteme fotovoltaice, sisteme de detectie incendiu, sisteme de desfumare, sisteme efractie, sisteme supraveghere, sisteme interfonie, control acces.",
      ],
      se_cere: [
        "Persoana disponibila sa invete lucruri noi si provocatoare;",
        "Persoana agera si isteata;",
        "Persoana care urmareste constant sa isi tina cuvantul si sa creasca profesional;",
        "Persoana vesela si sociabila;",
        "Persoana care cunoaste aspecte generale tehnice de instalatii civile si industriale de curenți tari și slabi, sisteme fotovoltaice, sisteme detectie incendiu, sisteme de desfumare, sisteme interfonie, sisteme control acces, sisteme supraveghiere;",
        "Persoana care poate mentine frumos si placut relatiile cu clienti si angajati;",
        "Cunoaste engleza la nivel intermediar +;",
        "Posesor permis conducere tip B;",
        "Experienta si abilitati de preluare a sarcinilor, de planificare, de coordonare si integrare echipa/muncitorii in santiere;",
        "Experienta si cunostinte in citire planuri, liste, scheme diverse;",
        "Experienta si cunostinte in intocmirea necesarelor de materiale si urmarirea consumurilor de materiale pe lucrari; ",
        "Experienta si cunostinta in folosire, urmarirea si gestionarea responsabila a sculelor si utilajelor;",
        "Experienta si cunostinte in alocarea si urmarirea sarcinilor date la muncitori;",
        "Experienta si cunostinte de trasarea de circuite, carotarii, slituirii, montaj doze, pozare de tuburi, montaj TD, legaturi in TD si doze;",
        "Experienta si cunoștințe de cablaj curenți tari și slabi;",
        "Experienta si cunoștințe de montaj jgheaburi, corpuri iluminat, iluminat panica, aparataje, senzori, montaje mecanice și ansamblari;",
        "Experienta si cunostinte de montaje si conectare-racordare: FDCP, tablouri electrice, tablouri electrice diverse, centrale desfumare si incendiu, ccTV, interfonie, supraveghere, control acces; ",
        "Experienta si cunostinte în Intervenții si mentenanță, constituie avantaj;",
        "Experienta si cunoștințe despre sisteme fotovoltaice: montaj panouri, montaj structuri, montaj invertoare, realizare tablouri electrice DC si AC afferente invertoarelor, punerea lor în funcțiune, remedierea defectelor, întreținerea lor, constituie avantaj;",
        "Experienta si cunoastere AUTOCAD (nivel incepator) si pachetul MicroSoft Office( word, excel), nivel intermediar, constituie avantaj dar nu conditia eliminatorie;",
        "Urmarirea si gestionarea proiectelor de instalatii electrice de curenți tari și slabi: sisteme fotovoltaice, sisteme detectie incendiu, sisteme de desfumare, sisteme interfonie, sisteme control acces, sisteme supraveghiere, mentenanta;",
        "Realizeaza rapoarte zilnice / saptamanale / lunare;",
        "Realizeaza verificari calitative si cantitative in santiere;",
        "Realizeaza PIF-uri la sisteme, echipamente si instalatii de curenți tari și slabi;",
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice;",
        "Asistenta in invatarea si insusirea programelor de lucru specific;",
        "Masina de serviciu;",
      ]
    }),
    new JobDescription({
      imageName: '2.png',
      isActive: true,
      name: "INGINER CURENTI SLABI",
      criterii_selectie: ["Minim 1 an experiență în Constructii / Instalatii / Inginerie / Proiectare / Energetica;", "Integru;"],
      canditatul_ideal: [
        "Esti student experimentat cu experienta in an terminal, sau inginer absolvent, vrei sa incepi sa cladesti o cariera profesionala, deasemnea consideri ca te incadrezi in descrierea de mai jos? Atunci te asteptam in echipa noastra!",
        "Cunostinte teoretice minime tehnice in constructii, instalatii curenți tari și slabi;",
        "Vrei sa activezi in domeniul de instalații electrice/ echipamente de securitate, control acces, efracție, incendiu, BMS;",
        "Vrei sa inveti despre echipamentele de securitate pentru control acces, supraveghere, protecție perimetrală, detecție a efracției, detective si alarmare incendiu, desfumare;",
        "Esti disponibil/ă pentru deplasări în țară/ lucru pentru intervenții in proportie de 30% din an;",
        "Cunoștințe de operare calculator;",
        "Permis de conducere auto categoria B;",
        "Atitudine pozitivă și esti orientat spre obiective;",
        "Studii de specialitate (profil electric/ electronic/ telecomunicatii);",
        "Vrei sa aprofundezi in domeniul sistemelor de alarma si detectie la efractie, supraveghere video, sisteme avertizare la incendiu, desfumare;",
        "Responsabilitate, mod riguros de organizare a propriei activitati, rabdare, meticulozitate, dinamism, bune abilitati de comunicare si relationare la orice nivel, spirit de echipa, vigoare, disponibilitate pentru deplasari in tara de scurta durata;",
        "Vei desfășura activități specifice de instalări, service și mentenanță pentru sisteme de securitate într-o manieră profesională și “customer oriented” în relație cu clienții, furnizorii, colaboratorii, respectiv angajații companiei;",
        "Vei face Instalari, reparatii, mentenanta sistemelor electronice de securitate, service garantie si post garantie. Interventii corective pentru sisteme electronice de securitate (detectie efractie, detectie incendiu, supraveghere video, control acces). Verificari periodice pentru sisteme electronice de Securitate;",
        "O buna relationare cu clientii, colegii si furnizorii;",
        "O persoana organizata;",
        "Spirit de echipa;",
        "Capacitate de rezolvare probleme, initiativa;",
        "Atitudine de perfectionist si consecventa in lucru;",
        "Bune abilititati de comunicare si relationare, abilităti de coordonare;",
        "Salariul este de inceput, pe cateva luni, in functie de evolutia angajatului, salariu se va mari periodic.",
      ],
      descrierea_jobului: [
        "Ne dorim in echipa un Inginer incepator, sau student pe profilele electrice;",
        "Vei desfășura activități specifice de instalări, service și mentenanță pentru sisteme de securitate într-o manieră profesională și “customer oriented” în relație cu clienții, furnizorii, colaboratorii, respectiv angajații companiei;",
        "Vei face Instalari, reparatii, mentenanta sistemelor electronice de securitate, service garantie si post garantie. Interventii corective pentru sisteme electronice de Securitate: detectie efractie, detectie incendiu, desfumare, supraveghere video, control acces. Verificari periodice pentru sisteme electronice de Securitate;",
        "Job-ul presupune relationarea intre departamentul de executie, cel tehnic, cu furnizorii si clientul final cu privire la implementarea proiectelor de instalatii, a diverselor sisteme electrice si energetice. Candidatul va tine legatura cu departamentul de furnizorii, va prelua detaliile tehnice ale proiectelor cu privire la realizarea instalatilor, montajul echipamentelor si va indruma echipa de excutie pentru realizarea lucrarilor.",
        "Va asigura consilierea clientului cu privire la pregatirea terenului in vederea implementarii proiectelor si va tine legatura cu acesta pentru coordonarea cu echipele de executie;",
        "Activitatea job-ul se va desfasura pe teren (85%) si de la birou (15%).",
      ],
      se_cere: [
        "Activități de instalare: cablare structurată (Cat. 5e, Cat. 6) și sisteme de securitate (antiefracție, control acces, TVCI, detecție și alarmare incendiu);",
        "Activități de service și mentenanță preventivă/ corectivă pentru sistemele de securitate;",
        "Operațiuni tehnice de extindere a sistemelor de securitate existente la clienți (cablare, conectare, punere în funcțiune, etc);",
      ],
      se_ofera: [
        " Training on the job alături de colegii noștri seniori;",
        "Prima de Craciun si Paste;",
        "Salarizare motivanta in functie de realizari si nivelul de cunoștințe, autonomie si responsabilitati;",
        "Posibilitate de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute; ",
        "Program de lucru flexibil, depinde de sezon - 8h/zi, 5 zile/saptamana;",
        "Masina se serviciu;",
      ]
    }),
    new JobDescription({
      imageName: '2.png',
      isActive: true,
      name: "INGINER RESPONSABIL LUCRARI FOTOVOLTAICE",
      criterii_selectie: ["Minim 1 an experiență în Constructii / Instalatii / Inginerie / Proiectare / Energetica;", "Integru;"],
      canditatul_ideal: [
        "Absolvent al Facultatii de Electrotehnica / Instalatii / Inginerie / Energetica;",
        "Minim 1 an experienta intr-un post similar;"
      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii electrice interioare si exterioare de joasa tensiune pentru constructii civile si industriale bransamente, curenti tari, curenti slabi;",
        "Executie de sisteme: sisteme fotovoltaice,"
      ],
      se_cere: [
        "Obtinerea avizelor de la institutii;",
        "Persoana disponibila sa invete lucruri noi si provocatoare, agera, isteata, vesela si sociabila;",
        "Persoana care urmareste constant sa isi tina cuvantul si sa creasca profesional;",
        "Persoana care cunoaste aspecte generale tehnice de instalatii civile si industriale de curenți tari și slabi, sisteme fotovoltaice, sisteme detectie incendiu, sisteme interfonie, sisteme control acces, sisteme supraveghiere;",
        "Persoana care poate mentine frumos si placut relatiile cu clienti si angajati;",
        "Cunoaste engleza la nivel intermediar +;",
        "Posesor permis conducere tip B;",
        "Experienta si abilitati de preluare a sarcinilor si de coordonare si integrare echipa/muncitorii in santiere;",
        "Experienta si cunostinte in citire planuri, liste, scheme diverse;",
        "Experienta si cunostinte in intocmirea necesarelor de materiale si urmarirea consumurilor de materiale pe lucrari;",
        "Experienta si cunostinta in folosire, urmarirea si gestionarea responsabila a sculelor si utilajelor;",
        "Experienta si cunostinte in alocarea si urmarirea sarcinilor date la muncitori;",
        "Experienta si cunoștințe de cablaj curenți tari și slabi;",
        "Experienta si cunoștințe de montaj jgheaburi, panouri si structuri fotovoltaice, aparataje, senzori, montaje mecanice și ansamblari, inveroare;",
        "Experienta si cunostinte de montaje si conectare-racordare: FDCP, tablouri electrice, tablouri electrice diverse.",
        "Experienta si cunostinte în Intervenții si mentenanță, constituie avantaj;",
        "Experienta si cunoștințe despre sisteme fotovoltaice: montaj panouri, structuri, invertoare, punerea lor în funcțiune, remedierea defectelor, întreținerea lor, tablouri electrice AC si DC;",
        "Experienta si cunoastere AUTOCAD (nivel incepator) si pachetul MicroSoft Office( word, excel), nivel intermediar, constituie avantaj dar nu conditia eliminatorie;",
        "Urmarirea si gestionarea proiectelor de sisteme fotovoltaice, ",
        "Realizeaza rapoarte zilnice / saptamanale / lunare;",
        "Realizeaza verificari calitative si cantitative in santiere;",
        "Realizeaza PIF-uri la sisteme, echipamente si instalatii de curenți tari și slabi;",
      ],
      se_ofera: [
        "Pachet salarial atractiv;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala, traininguri periodice;",
        "Asistenta in invatarea si insusirea programelor de lucru specific;",
        "Masina de serviciu;",
      ]
    }),
    new JobDescription({
      imageName: '2.png',
      isActive: true,
      name: "INGINER INTERNSHIP/DEBUTANT",
      criterii_selectie: "Integru;",
      canditatul_ideal: [
        "Esti student fara experienta in an terminal, sau inginer absolvent, vrei sa incepi sa cladesti o cariera profesionala, deasemnea consideri ca te incadrezi in descrierea de mai jos? Atunci te asteptam in echipa noastra!",
        "Cunostinte teoretice minime tehnice in constructii, instalatii curenți tari și slabi, sisteme fotovoltaice, eficienta energetica;",
        "Vrei sa activezi in domeniul de instalații electrice/ echipamente de securitate, control acces, efracție, incendiu;",
        "Vrei sa inveti despre echipamentele de securitate pentru control acces, supraveghere, protecție perimetrală, detecție a efracției, detectie si alarmare incendiu, desfumare;",
        "Esti disponibil/ă pentru deplasări în țară/ lucru pentru intervenții in proportie de 30% din an;",
        "Cunoștințe de operare calculator;",
        "Permis de conducere auto categoria B;",
        "Atitudine pozitivă și esti orientat spre obiective;",
        "Studii de specialitate: profil electric/ electronic/ telecomunicatii;",
        "Vrei sa aprofundezi in domeniul sistemelor de alarma si detectie la efractie, supraveghere video, sisteme avertizare la incendiu, desfumari, sisteme fotovoltaice;",
        "Responsabilitate, mod riguros de organizare a propriei activitati, rabdare, meticulozitate, dinamism, bune abilitati de comunicare si relationare la orice nivel, spirit de echipa, vigoare, disponibilitate pentru deplasari in tara de scurta durata;",
        "Vei desfășura activități specifice de instalări, service și mentenanță pentru sisteme de securitate într-o manieră profesională și “customer oriented” în relație cu clienții, furnizorii, colaboratorii, respectiv angajații companiei;",
        "Vei face Instalari, reparatii, mentenanta sistemelor electronice de securitate, service garantie si post garantie. Interventii corective pentru sisteme electronice de Securitate, detectie efractie, detectie incendiu, supraveghere video, control access, sisteme fotovoltaice. Verificari periodice pentru sisteme electronice de Securitate;",
        "O buna relationare cu clientii, colegii si furnizorii;",
        "O persoana organizata;",
        "Spirit de echipa;",
        "Capacitate de rezolvare probleme, initiativa;",
        "Atitudine de perfectionist si consecventa in lucru;",
        "Bune abilititati de comunicare si relationare, abilităti de coordonare;",
        "Salariul este de inceput, pe cateva luni, in functie de evolutia angajatului, salariu se va mari periodic.",
      ],
      descrierea_jobului: [
        "Inginer Responsabil Audit si Eficienta Energetica;",
        "Inginer Responsabil Lucrari generale de Instalatii;",
        "Inginer Curenti Slabi;",
        "Inginer Responsabil Lucrari Fotovoltaice;",
        "Elaborarea documentației tehnice aferentă instalațiilor pentru construcții specialitatea electrice;",
        "Atingerea obiectivelor de dezvoltarea a departamentului, în concordanță cu obiectivele de dezvoltare ale firmei;",
        "Ridicarea în permanență a nivelului calității activității de inginerie desfașurată în cadrul firmei;",
        "Preocuparea în permanență pentru respectarea SMC de către propria persoană;",
      ],
      se_cere: [
        "Dorinţa de a lucra în activitatea de inginerie, concepţie-proiectare, implementare;",
        "Abilităţi de comunicare şi disponibilitate la lucru în echipă;",
        "Capacitate de a lucra fără îndrumare;",
        "Spirit de iniţiativă şi gândire practică;",
        "Receptivitate la nou şi adaptare la schimbare;",
        "Autodidact;",
        "Modestie şi atitudine corectă;",
        "Atenție la detalii;",
      ],
      se_ofera: [
        "Salarizare motivanta in functie de realizari;",
        "Prima de Craciun si Paste;",
        "Oportunitati de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute, autonomie, responsabilitati;",
        "Plata salariului se face la 2 saptamani. Program de lucru flexibil depinde de sezon - 8h/zi, 5 zile/saptamana;",
        "Asistenta in invatarea si insusirea programelor de lucru specific, traininguri periodice;",
        "Masina se serviciu;",
      ]
    }),
    new JobDescription({
      imageName: '2.png',
      isActive: true,
      name: "SEF ECHIPA ELECTRICIENI",
      criterii_selectie: ["Peste 2 ani experiență în Constructii civile si industriale/ Instalatii/ Electricitate;", "integru;"],
      canditatul_ideal: [
        "Disponibil relocarilor constituie avantaj dar nu conditie eliminatorie;",
        "Apt de lucru la inaltime;",
        "Spirit de echipa;",
        "Seriozitate;",
        "Integritate;",
        "Experienta in domeniu constituie avantaj;",
        "Calificare in domeniul electronica/ automatizari/ instalatii electrice/ profil electro constituie avantaj;",
      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii electrice interioare si exterioare de joasa tensiune pentru constructii civile si industrial, bransamente, curenti tari, curenti slabi;",
        "Executie de sisteme: sisteme fotovoltaice, sisteme de detectie incendiu, sisteme de desfumare, sisteme efractie, sisteme supraveghere, sisteme interfonie, control acces;"
      ],
      se_cere: [
        "Persoana disponibila sa invete lucruri noi si provocatoare;",
        "Persoana agera si isteata;",
        "Persoana care urmareste constant sa isi tina cuvantul si sa creasca profesional;",
        "Persoana vesela si sociabila;",
        "Persoana care cunoaste aspecte generale tehnice de instalatii civile si industriale de curenți tari și slabi, sisteme fotovoltaice, sisteme detectie incendiu, sisteme de desfumare, sisteme interfonie, sisteme control acces, sisteme supraveghiere;",
        "Persoana care poate mentine frumos si placut relatiile cu clienti si angajati;",
        "Cunoaste engleza constituie avantaj dar nu conditie eliminatorie;",
        "Posesor permis conducere tip B;",
        "Experienta si abilitati de preluare a sarcinilor, planificare, de coordonare si integrare echipa/muncitorii in santiere;",
        "Experienta si cunostinte in citire planuri, liste, scheme diverse;",
        "Experienta si cunostinte in intocmirea necesarelor de materiale si urmarirea consumurilor de materiale pe lucrari; ",
        "Experienta si cunostinta in folosire, urmarirea si gestionarea responsabila a sculelor si utilajelor;",
        "Experienta si cunostinte in alocarea si urmarirea sarcinilor date la muncitori;",
        "Experienta si cunostinte de trasarea de circuite, carotarii, slituirii, montaj doze, pozare de tuburi, montaj TD, legaturi in TD si doze;",
        "Experienta si cunoștințe de cablaj curenți tari și slabi;",
        "Experienta si cunoștințe de montaj jgheaburi, corpuri iluminat, iluminat panica, aparataje, senzori, montaje mecanice și ansamblari;",
        "Experienta si cunostinte de montaje, conectare-racordare si PIF-uri la FDCP, tablouri electrice, tablouri electrice diverse, centrale desfumare si incendiu, ccTV, interfonie, supraveghere, control acces; ",
        "Experienta si cunostinte în Intervenții si mentenanță, constituie avantaj;",
        "Experienta si cunoștințe despre sisteme fotovoltaice, montarea si cablarea sistemelor fotovoltaice, punerea lor în funcțiune, remedierea defectelor, întreținerea lor;",
        "Urmarirea si gestionarea sarcinilor de instalatii electrice de curenți tari și slabi, sisteme fotovoltaice, sisteme detectie incendiu, sisteme de desfumare, sisteme interfonie, sisteme control acces, sisteme supraveghiere, mentenanta;",
        "Gestionare si urmarirea muncitorilor in santiere;",
        "Realizeaza rapoarte zilnice / saptamanale / lunare;",
        "Realizeaza verificari calitative si cantitative in santiere;",
        "Realizeaza PIF-uri la echipamente si instalatii de curenți tari și slabi;",
      ],
      se_ofera: [
        "Salarizare motivanta in functie de realizari;",
        "Prima de Craciun si Paste;",
        "Oportunitate de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute, autonomie, responsabilitati;",
        "Plata salariului se face la 2 saptamani. Program de lucru flexibil depinde de sezon - 8h/zi, 5 zile/saptamana;",
        "Masina se serviciu;",
        "Asistenta in invatarea si insusirea programelor de lucru specific, traininguri periodice;",
      ]
    }),
    new JobDescription({
      isActive: true,
      name: "ELECTRICIAN INSTALATII ELECTRICE",
      criterii_selectie: "Peste 1 an experiență în Constructii civile si industriale/ Instalatii/ Electricitate;",
      canditatul_ideal: [
        "Disponibil relocarilor constituie avantaj dar nu conditie eliminatorie;",
        "Apt de lucru la inaltime constituie avantaj; ",
        "Spirit de echipa; ",
        "Seriozitate; ",
        "Experienta in domeniu constituie un avantaj; ",
        "Calificare in domeniul electronica/ automatizari/ instalatii electrice/ profil electro constituie avantaj;",

      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii electrice interioare si exterioare de joasa tensiune pentru constructii civile si industrial, bransamente, curenti tari, curenti slabi; ",
        "Executie de sisteme: sisteme fotovoltaice, sisteme de detectie incendiu, sisteme de desfumare, sisteme efractie, sisteme supraveghere, sisteme interfonie, control acces."

      ],
      se_cere: [
        "Experienta si cunostinte de trasarea de circuite, carotarii, slituirii, montaj doze, pozare de tuburi, montaj TD, legaturi in TD si doze;",
        "Experienta si cunoștințe de cablari: curenți tari și slabi;",
        "Experienta si cunoștințe de montaj: jgheaburi, corpuri iluminat, iluminat panica, aparataje, senzori, montaje mecanice și ansamblari;",
        "Experienta si cunostinte de montaje, conectare-racordare si PIF-uri la: FDCP, tablouri electrice, tablouri electrice diverse, centrale desfumare si incendiu, ccTV, interfonie, supraveghere, control acces, constituie avantaj dar nu este conditie eliminatorie;",
        "Experienta si cunostinte în Intervenții si mentenanță, constituie avantaj, dar nu condiție eliminatorie;",
        "Experienta si cunoștințe despre sisteme fotovoltaice: montaje panouri, montaj de structura, montaj de invertoare, tablouri DC si AC, echipament aferent, punerea lor în funcțiune, remedierea defectelor, întreținerea lor, constituie avantaj, dar nu este conditie eliminator;",
      ],
      se_ofera: [
        "Salarizare motivanta in functie de realizari; ",
        "Prima de Craciun si Paste;",
        "Posibilitate de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute, autonomie, responsabilitati; ",
        "Plata salariului se face la 2 saptamani. Program de lucru flexibil depinde de sezon - 8h/zi, 5 zile/saptamana; ",

      ]
    }),
    new JobDescription({
      isActive: true,
      name: "TEHNICIAN CURENTI SLABI",
      criterii_selectie: "Peste 1 an experiență într-un post similar si experienta in Inginerie, Constructii / Instalatii;",
      canditatul_ideal: [
        "Experiență în instalații electrice/ echipamente de securitate, control acces, efracție, incendiu;",
        "Cunoștințe solide despre echipamentele de securitate pentru control acces, supraveghere, protecție perimetrală, detecție a efracției, detective si alarmare incendiu;",
        "Disponibil/ă pentru deplasări în țară/ lucru pentru intervenții in proportie de 30% din an;",
        "Permis de conducere auto categoria B;",
        "Atitudine pozitivă și esti orientat spre obiective;",
        "Studii de specialitate; profil electric/ electronic/ telecomunicatii;",
        "Cunostinte in domeniul sistemelor de alarma si detectie la efractie, supraveghere video, sisteme avertizare la incendiu, desfumare;",
        "Responsabilitate, mod riguros de organizare a propriei activitati, rabdare, meticulozitate, dinamism, bune abilitati de comunicare si relationare la orice nivel, spirit de echipa, vigoare, disponibilitate pentru deplasari in tara de scurta durata;",
      ],
      descrierea_jobului: [
        "Vei desfășura activități specifice de instalări, service și mentenanță pentru sisteme de securitate într-o manieră profesională și “customer oriented” în relație cu clienții, furnizorii, colaboratorii, respectiv angajații companiei;",
        "Vei face Instalari, reparatii, mentenanta sistemelor electronice de securitate, service garantie si post garantie. Interventii corective pentru sisteme electronice de securitate detectie efractie, detectie incendiu, supraveghere video, control acces. Verificari periodice pentru sisteme electronice de Securitate;"
      ],
      se_cere: [
        "Activități de instalare: cablare structurată (Cat. 5e, Cat. 6) și sisteme de Securitate, antiefracție, control acces, TVCI, detecție și alarmare incendiu, desfumare;",
        "Activități de service și mentenanță preventivă/ corectivă pentru sistemele de securitate;",
        "Operațiuni tehnice de extindere a sistemelor de securitate existente la clienți (cablare, conectare, punere în funcțiune, etc);",
      ],
      se_ofera: [
        "Training on the job alături de colegii noștri seniori;",
        "Prima de Craciun si Paste;",
        "Salarizare motivanta in functie de realizari si nivelul de cunoștințe, autonomie, responsabilitati si cu posibilitate de crestere in functie de performantele avute;",
        "Posibilitate de dezvoltare profesionala;",
        "Program de lucru flexibil, depinde de sezon - 8h/zi, 5 zile/saptamana;"
      ]
    }),
    new JobDescription({
      isActive: true,
      name: "TEHNICIAN FOTOVOLTAICE",
      criterii_selectie: "Peste 1 an experiență în Constructii civile si industriale/ Instalatii/ Electricitate;",
      canditatul_ideal: [
        "Disponibil relocarilor constituie avantaj dar nu conditie eliminatorie;",
        "Apt de lucru la inaltime constituie avantaj; ",
        "Spirit de echipa; ",
        "Seriozitate; ",
        "Experienta in domeniu constituie avantaj;",
        "Calificare in domeniul fotovoltaic / electronica / automatizari / instalatii electrice / profil electro constituie avantaj;",
      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii fotovoltaice si instalatii de joasa tensiune pentru constructii civile si industrial. ",
        "Executie de sisteme: sisteme fotovoltaice la cheie.",
      ],
      se_cere: [
        "Experienta si cunostinte de trasarea de circuite, montaj doze, pozare de tuburi, montaj TD, legaturi in TD si doze, legaturi in firide, manevre in firide si tablouri; ",
        "Experienta si cunoștințe de cablari: curenți tari și slabi;",
        "Experienta si cunoștințe de montaj: jgheaburi, panouri fotovoltaice, structura panouri, invertoare, aparataje, montaje mecanice și ansamblari, tablouri electrice DC si AC;",
        "Experienta si cunoștințe despre sisteme fotovoltaice, punerea lor în funcțiune, remedierea defectelor, întreținerea lor.",
        "Experienta si cunostinte de montaje, conectare-racordare si PIF-uri la: FDCP, tablouri electrice, tablouri electrice diverse;",
        "Experienta si cunostinte în Intervenții si mentenanță, constituie avantaj, dar nu condiție eliminatorie;",
      ],
      se_ofera: [
        "Salarizare motivanta in functie de realizari; ",
        "Prima de Craciun si Paste;",
        "Oportunitate de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute, autonomie, responsabilitati; ",
        "Plata salariului se face la 2 saptamani. Program de lucru flexibil depinde de sezon - 8h/zi, 5 zile/saptamana; ",
      ]
    }),
    new JobDescription({
      isActive: true,
      name: "AJUTOR ELECTRICIAN SI TEHNICIAN INSTALATII",
      criterii_selectie: "Experiență minima în Constructii civile si industriale/ Instalatii/ Electricitate;",
      canditatul_ideal: [
        "Disponibil relocarilor;",
        "Apt de lucru la inaltime constituie avantaj; ",
        "Spirit de echipa; ",
        "Seriozitate; ",
        "Experienta in domeniu constituie un avantaj; ",
        "Calificare in domeniul electronica/ automatizari/ instalatii electrice/ profil electro constituie avantaj;",
      ],
      descrierea_jobului: [
        "Executie lucrari de instalatii electrice interioare si exterioare de joasa tensiune pentru constructii civile si industrial, bransamente, curenti tari, curenti slabi; ",
        "Executie de sisteme: sisteme fotovoltaice, sisteme de detectie incendiu, sisteme de desfumare, sisteme efractie, sisteme supraveghere, sisteme interfonie, control acces;",

      ],
      se_cere: [
        "Harnic;",
        "Serios si ascultator;",
        "Se supune si preia sarcini de la responsabili;",
        "Conditie fizica buna;",
        "Minim de experienta si cunostinte de operarea si lucrul cu sculele;",
        "Minim de experienta si cunostinte de trasarea de circuite, carotarii, slituirii, montaj doze, pozare de tuburi, montaj TD, legaturi in TD si doze;",
        "Minim de experienta si cunoștințe de cablari: curenți tari și slabi;",
        "Minim de experienta si cunoștințe de montaj: jgheaburi, corpuri iluminat, iluminat panica, aparataje, senzori, montaje mecanice și ansamblari;",
        "Consecvent si etica in munca;",

      ],
      se_ofera: [
        "Salarizare motivanta in functie de realizari; ",
        "Prima de Craciun si Paste;",
        "Oportunitate de dezvoltare profesionala. Salariu atractiv cu posibilitate de crestere in functie de performantele avute, autonomie, responsabilitati; ",
        "Plata salariului se face la 2 saptamani. Program de lucru flexibil depinde de sezon - 8h/zi, 5 zile/saptamana; ",
      ]
    }),
  ];
}

export class JobDescription {
  isActive: boolean = true;
  imageName: string = '1.png';
  name: string;
  criterii_selectie: any;
  canditatul_ideal: string[];
  descrierea_jobului: string[];
  se_cere: string[];
  se_ofera: string[];

  public constructor(init?: Partial<JobDescription>) {
    Object.assign(this, init);
  }
}
