<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- OUR BLOG START -->
        <div class="section-full  p-t80 p-b50 bg-white">
            <div class="container">
            
                <!-- BLOG SECTION START -->
                <div class="section-content">
                    <div class="row d-flex justify-content-center">
                    
                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                           <!-- BLOG START -->
                            <div class="blog-post date-style-2 blog-lg">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a><img src="assets/images/blog/default/thum1.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white p-t30">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-category"><span>Materials</span> </li>
                                            <li class="post-date">February 28, 2021</li>
                                            <li class="post-comment">24 Comment</li>
                                        </ul>
                                    </div>                                 
                                    <div class="wt-post-title ">
                                        <h2 class="post-title">Technology is important for your business, you use technology makes</h2>
                                    </div>
                                   
                                    <div class="wt-post-text">
                                            <p>A collection of textile samples lay spread out on the table – Samsa was a travelling sales man – and above it there hung a picture that he had recently cut out of an illustrated an magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower army towards the viewer. Gregor then turned to look out the window at the dull weather. Pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. “What’s happened to me?” he thought. It wasn’t a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar wallsmuff that covered the whole of her lower arm towards the viewer.</p>
                                        <p>Industro is the specialised IoT company within the Industris Group, one of the world’s major mobile operators. Building on almost 20 years of experience, Industris provides global lots connectivity and cloud services to enterprises with large fleets of connected devices well as third-party service providers. Telenor Connexion manages more than 10 million connect things in more than 200 countries for global customers including Volvo, Scania, Hitachi, are Securitas Direct and Husqvarna. With headquarters and tech centre located in Sweden, the company has regional offices in the UK, US, Germany and Japan.</p>
                                        
                                         <blockquote>
                                            <i class="fa fa-quote-left font-20"></i>
                                            <p>I think it’s important people see themselves in film, but it’s even more important they see people they maybe don’t know as well.</p>
                                            <span class="quoter">– Malcolm Franzcrip</span>
                                         </blockquote>                                         
   
                                    <p>An eros argumentum vel, elit diceret duo eu, quo et aliquid ornatus delicatissimi. Cu nam tale ferri utroque, eu habemus albucius mel, cu vidit possit ornatus eum. Eu ius postulant salutatus definitionem, an e trud erroribus explicari. Graeci viderer qui ut, at habeo facer solet usu. Pri choro pertinax indoctum ne, ad partiendo persecuti forensibus est.</p>
                                    </div>  
                                                    
                                </div>
                            </div>
                            
                            <div class="widget bg-white  widget_tag_cloud">
                                <h4 class="tagcloud">Tags</h4>
                                <div class="tagcloud">
                                    <a routerLink="/blog-list">Analytics</a>
                                    <a routerLink="/blog-list">Business </a>
                                    <a routerLink="/blog-list">Consulting</a>                                            
                                    <a routerLink="/blog-list">Solutions </a>
                                    <a routerLink="/blog-list">Development</a>
                                    <a routerLink="/blog-list">Stock</a>
                                    <a routerLink="/blog-list">Finance</a>
                                    <a routerLink="/blog-list">Innovation</a>
                                    <a routerLink="/blog-list">Investing</a>
                                    <a routerLink="/blog-list">Strategic</a>                                                
                                    <a routerLink="/blog-list">Management</a>
                                </div>
                            </div>                                

                            <div class="clear bg-gray p-a30" id="comment-list">
                                <div class="comments-area" id="comments">
                                    <h2 class="comments-title m-t0">2 Comments</h2>
                                    <div>
                                        <!-- COMMENT LIST START -->
                                        <ol class="comment-list">
                                            <li class="comment">
                                                <!-- COMMENT BLOCK -->
                                                <div class="comment-body">
                                                    <div class="comment-author vcard">
                                                        <img  class="avatar photo" src="assets/images/testimonials/pic1.jpg" alt="">
                                                        <cite class="fn">Janice Brown</cite>
                                                        <span class="says">says:</span>
                                                    </div>                                                
                                                    <div class="comment-meta">
                                                        <a routerLink="/other/team-single">24 Feb 2021</a>
                                                    </div>                                        
                                                    <p>The example about the mattress sizing page you mentioned in the last WBF Can be a perfect example of new keywords and content, and broadening the funnel as well. I can only imagine the sale are if that was the site of a mattress selling company.</p>
                                                    <div class="reply">
                                                        <a href="javascript:void(0);" class="comment-reply-link">Reply</a>
                                                    </div>
                                                </div>
                                                <!-- SUB COMMENT BLOCK -->
                                                <ol class="children">
                                                    <li class="comment odd parent">
                                                    
                                                        <div class="comment-body">
                                                            <div class="comment-author vcard">
                                                                <img  class="avatar photo" src="assets/images/testimonials/pic3.jpg" alt="">
                                                                <cite class="fn">Betty Riley</cite>
                                                                <span class="says">says:</span>
                                                            </div>                                                        
                                                            <div class="comment-meta">
                                                                <a routerLink="/other/team-single">28 Feb 2021</a>
                                                            </div>                                                
                                                            <p>Can be a perfect example of new keywords and content, and broadening the funnel as well. I can only imagine the sale are if that was the site of a mattress selling company.</p>
                                                            <div class="reply">
                                                                <a href="javascript:void(0);" class="comment-reply-link">Reply</a>
                                                            </div>
                                                        </div>                                                        
                                                        
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        <!-- COMMENT LIST END -->
                                        
                                        <!-- LEAVE A REPLY START -->
                                        <div class="comment-respond m-t30" id="respond">
            
                                            <h2 class="comment-reply-title" id="reply-title">Leave a Comments
                                                <small>
                                                    <a style="display:none;" href="javascript:void(0);" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</a>
                                                </small>
                                            </h2>
            
                                            <form class="comment-form" id="commentform" method="post" >
                                        
                                                <p class="comment-form-author">
                                                    <label for="author">Name <span class="required">*</span></label>
                                                    <input class="form-control" type="text" value="" name="user-comment"  placeholder="Author" id="author">
                                                </p>
                                                
                                                <p class="comment-form-email">
                                                    <label for="email">Email <span class="required">*</span></label>
                                                    <input class="form-control" type="text" value="" name="email" placeholder="Email">
                                                </p>
                                                
                                                <p class="comment-form-url">
                                                    <label for="url">Website</label>
                                                    <input class="form-control" type="text"  value=""  name="url"   placeholder="Website" id="url">
                                                </p>
                                                
                                                <p class="comment-form-comment">
                                                    <label for="comment">Comment</label>
                                                    <textarea class="form-control" rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                                </p>
                                                
                                                <p class="form-submit">
                                                    <button class="site-button site-btn-effect" type="button">Submit</button>
                                                </p>
                                                
                                            </form>
            
                                        </div>
                                        <!-- LEAVE A REPLY END -->
                                   </div>
                                </div>
                            </div>
                            <!-- BLOG END -->	
                             
                        </div> 
                        
                        <!-- SIDE BAR START -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar  m-b30">
                        
                            <aside  class="side-bar">
                                
                                   <!-- SEARCH -->
                                   <div class="widget p-a20">
                                        <div class="search-bx">
                                            <form role="search" method="post">
                                                <div class="input-group">
                                                    <input name="news-letter" type="text" class="form-control" placeholder="Write your text">
                                                    <span class="input-group-btn">
                                                        <button type="submit" class="btn"><i class="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    
                                   <!-- RECENT POSTS -->
                                   <app-section-recent-posts [data]="recent"></app-section-recent-posts>
                                    
                                   <!-- Archives -->   
                                   <app-section-archives [data]="archives"></app-section-archives>                                          
                                    
                                   <!-- CATEGORY -->   
                                   <app-section-categories [data]="categories"></app-section-categories>                                               
                                                                            
                                   <!-- TAGS -->
                                   <app-section-tags [data]="tags"></app-section-tags>
                                    
                                   <!-- Social -->
                                   <div class="widget p-a20">
                                        <div class="widget_social_inks">
                                            <ul class="social-icons social-square social-darkest social-md">
                                                <li><a href="https://www.facebook.com/" class="fa fa-facebook"></a></li>
                                                <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
                                                <li><a href="https://www.linkedin.com/" class="fa fa-linkedin"></a></li>
                                                <li><a href="https://rss.com/" class="fa fa-rss"></a></li>
                                                <li><a href="https://www.youtube.com/" class="fa fa-youtube"></a></li>
                                                <li><a href="https://www.instagram.com/" class="fa fa-instagram"></a></li>
                                            </ul>
                                        </div>
                                    </div>                                          
                              
                               </aside>
    
                        </div>
                        <!-- SIDE BAR END -->                         

                                                    
                    </div>
                                             
                </div>
                
            </div>
            
         </div>   
        <!-- OUR BLOG END -->
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
