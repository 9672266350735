import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fotovoltaice',
  templateUrl: './fotovoltaice.component.html',
  styleUrls: ['./fotovoltaice.component.css']
})
export class FotovoltaiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "SISTEME FOTOVOLTAICE",
  }


}
