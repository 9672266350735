<div class="section-full p-t90 p-b50 bg-no-repeat bg-bottom-left bg-white"  style="background-image:url(assets/images/background/building-bg.png);">
    <div class="about-section-11">
        <div class="container">
            <div class="section-content">                 
                <div class="row justify-content-center d-flex">
                
                    <div class="col-lg-6 col-md-12 m-b30">
                        <div class="about-section-11-right">
                            <!-- TITLE START-->
                            <div class="section-head left wt-small-separator-outer">
                                <div class="wt-small-separator site-text-primary">
                                    <div>{{data.title}}</div>
                                </div>
                                <h2>{{data.subtitle}}</h2>
                            </div>
                            <!-- TITLE END-->

                            <div class="about-one">
                                <p>
                                    {{data.description}}
                                </p>
                            </div>

                             <ul class="description-list p-b20">
                                <li *ngFor="let object of data.specifications"><i class="fa fa-angle-right"></i>{{object}}</li>
                            </ul>

                            <a routerLink="/about/about1" class="site-button site-btn-effect">More About</a> 
                       </div>
                   </div>

                    <div class="col-lg-6 col-md-12 m-b30 about-max-12-position">
                        <div class="about-max-12">
                            <span class="ipad-pencil"><img src="assets/images/home-11/background/ipad-pencil.png" class="ipad-pencil-jump"></span>
                            <div class="ser-ipad-warp">
                                <span class="ser-ipad-cam"></span>
                                <div class="ser-ipad-pic" style="background-image:url({{data.image}})">
                                  <div class="ser-ipad-overlay"></div>
                                  <a href="{{data.videoLink}}" class="mfp-video play-now-video">
                                    <i class="icon fa fa-play"></i>
                                    <span class="ripple"></span>
                                </a>
                                </div>
                                <span class="ser-ipad-btn"></span>
                            </div>
                        </div>
                    </div>  
                                          
               </div>
            </div> 
        </div>
    </div>
</div> 