import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-s-mechanical',
  templateUrl: './page-s-mechanical.component.html',
  styleUrls: ['./page-s-mechanical.component.css']
})
export class PageSMechanicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
