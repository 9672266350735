<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->
            
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->
        
        <!-- SECTION CONTENT START -->
        <div class="section-full p-t80 p-b50">
            <div class="container">
                <div class="section-content">

                    <div class="row d-flex justify-content-center">
                    
                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">                       
                        
                            <div class="row">
                                <!-- COLUMNS 1 -->
                                <div class="col-lg-6 col-md-6 m-b30" *ngFor="let object of products">
                                    <div class="wt-box wt-product-box block-shadow  overflow-hide">
                                        <div class="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
                                            <img src="{{object.image}}" alt="">
                                            <div class="overlay-bx">
                                                <div class="overlay-icon">
                                                    <a routerLink="/products/shopping-cart">
                                                        <i class="fa fa-cart-plus wt-icon-box-xs"></i>
                                                    </a>
                                                    <a class="mfp-link" routerLink="/products/wish-list">
                                                        <i class="fa fa-heart wt-icon-box-xs"></i>
                                                    </a>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="wt-info  text-center">
                                             <div class="p-a20 bg-white">
                                                <h3 class="wt-title">
                                                    <a routerLink="/products/product-detail">{{object.title}}</a>
                                                </h3>
                                                <span class="price">
                                                    <ins>
                                                        <span><span class="Price-currencySymbol">$ </span>{{object.price}}</span>
                                                    </ins>
                                                </span>
    

                                             </div>
                                        </div>
                                    </div>
                                </div>
                                                                  
                            </div>
                        
                        </div>
                        
                        <!-- SIDE BAR START -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar  m-b30">
                        
                            <aside  class="side-bar">
                                
                                   <!-- SEARCH -->
                                   <div class="widget p-a20">
                                        <div class="search-bx">
                                            <form role="search" method="post">
                                                <div class="input-group">
                                                    <input name="news-letter" type="text" class="form-control" placeholder="Write your text">
                                                    <span class="input-group-btn">
                                                        <button type="submit" class="btn"><i class="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    
                                   <!-- RECENT POSTS -->
                                   <app-section-recent-posts [data]="recent"></app-section-recent-posts>
                                    
                                   <!-- Archives -->   
                                   <app-section-archives [data]="archives"></app-section-archives>                                          
                                    
                                   <!-- CATEGORY -->   
                                   <app-section-categories [data]="categories"></app-section-categories>                                               
                                                                            
                                   <!-- TAGS -->
                                   <app-section-tags [data]="tags"></app-section-tags>
                                    
                                   <!-- Social -->
                                   <div class="widget p-a20">
                                        <div class="widget_social_inks">
                                            <ul class="social-icons social-square social-darkest social-md">
                                                <li><a href="https://www.facebook.com/" class="fa fa-facebook"></a></li>
                                                <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
                                                <li><a href="https://www.linkedin.com/" class="fa fa-linkedin"></a></li>
                                                <li><a href="https://rss.com/" class="fa fa-rss"></a></li>
                                                <li><a href="https://www.youtube.com/" class="fa fa-youtube"></a></li>
                                                <li><a href="https://www.instagram.com/" class="fa fa-instagram"></a></li>
                                            </ul>
                                        </div>
                                    </div>                                          
                              
                               </aside>
    
                        </div>
                        <!-- SIDE BAR END -->                         
                    </div>
                </div>
            </div>
        </div>
         <!-- SECTION CONTENT END -->
         
        <!-- SECTION CONTENT START -->
        <app-section-featured-products [data]="featured"></app-section-featured-products>
        <!-- SECTION CONTENT END -->             
    
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
