<div class="section-full p-t80 p-b80 overlay-wraper  bg-gray full-container-margin">
                                   
    <div class="section-content">

        <div class="container">                                
            <!-- TITLE START-->
            <div class="section-head center wt-small-separator-outer text-center">
                <div class="wt-small-separator site-text-primary">
                    <div  class="sep-leaf-left"></div>
                    <div>{{data.title}}</div>
                    <div  class="sep-leaf-right"></div>
                </div>
                <h2>{{data.subtitle}}</h2>
            </div>
            <!-- TITLE END--> 
           </div>                                
            <div class="container-fluid">
            <div class="how-we-work-section">
                <div class="owl-carousel how-we-work owl-btn-vertical-center">
                
                    <div class="item" *ngFor="let object of data.work">
                        <div class="how-we-work-box">
                        
                            <div class="wt-box how-we-work-box-1 bg-white">
                                <div class="box-icon-box-wraper">
                                    <div class="box-icon-number site-text-primary"><span>{{object.count}}</span></div>
                                </div>
                                <div class="how-we-work-title title-style-2 site-text-secondry">
                                    <span class="s-title-one">{{object.title}}</span>
                                </div>
                                <div class="how-we-work-content">
                                    <p>{{object.description}}</p>
                                    <a routerLink="/services/s-oilgas" class="site-button-link">Read More</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>                                                                                                                                                           
            
            
                </div>
            </div>
            </div>


    </div>                                        

</div>