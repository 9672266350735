<div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header"
    data-source="gallery" style="background:#eeeeee;padding:0px;">
    <div id="welcome" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
        <ul>

            <!-- SLIDE 1 -->
            <li data-index="rs-901" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
                data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
                data-thumb="assets/media/images/video1.jpg" data-rotate="0" data-fstransition="fade"
                data-fsmasterspeed="300" data-fsslotamount="7" data-saveperformance="off" data-title="Slide Title"
                data-param1="Additional Text" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                <!-- MAIN IMAGE -->

                <!-- LAYERS -->
                <!-- LAYER NR. 1 [ for overlay ] -->
                <div class="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-0"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                    data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide"
                    data-responsive_offset="off" data-responsive="off" data-frames='[
                {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                    style="z-index: 1;background-color:rgba(0, 0, 0, 0.6);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                </div>

                <!-- LAYER NR. 2 [ for video ] -->
                <div class="rs-background-video-layer" data-forcerewind="on" data-volume="mute" data-videowidth="100%"
                    data-videoheight="100%" data-videomp4="assets/media/video/video1.mp4" data-videopreload="auto"
                    data-videoloop="loop" data-aspectratio="16:9" data-autoplay="true"
                    data-autoplayonlyfirsttime="false">
                </div>


                <!-- LAYER NR. 3 [ Black Box ] -->
                <div class="tp-caption   tp-resizeme" id="slide-901-layer-3"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['top','top','top','top']" data-voffset="['200','200','200','200']"
                    data-fontsize="['20','20','20','20']" data-lineheight="['56','56','56','50']"
                    data-width="['800','800','600','300']" data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                    data-frames='[
                {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                ]' data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
                white-space: normal; 
                font-weight: 600;
                color:#fff;
                border-width:0px;font-family: 'Roboto', sans-serif; text-transform:uppercase;">
                    <div class="site-text-primary">We Make Sure</div>
                </div>



                <!-- LAYER NR. 4 [ for title ] -->
                <div class="tp-caption   tp-resizeme" id="slide-901-layer-4"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['top','top','top','top']" data-voffset="['260','260','260','260']"
                    data-fontsize="['70','56','38','28']" data-lineheight="['80','66','48','38']"
                    data-width="['800','800','600','300']" data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                    data-frames='[
                {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                ]' data-textAlign="['center','center','center','center']" data-paddingtop="[5,5,5,5]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
                white-space: normal; 
                font-weight: 900;
                color:#fff;
                border-width:0px;font-family: 'Roboto', sans-serif; text-transform:uppercase;">We are the best
                    construction company
                </div>

                <!-- LAYER NR. 5 [ for paragraph] -->
                <div class="tp-caption  tp-resizeme" id="slide-901-layer-5"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['top','top','top','top']" data-voffset="['450','450','380','360']"
                    data-fontsize="['16','16','16','14']" data-lineheight="['30','30','30','22']"
                    data-width="['700','700','600','300']" data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']" data-type="text" data-responsive_offset="on"
                    data-frames='[
                {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                ]' data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13; 
                font-weight: 500; 
                color:#fff;
                border-width:0px;">Industro Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since.</div>

                <!-- LAYER NR. 6 [ for see all service botton ] -->
                <div class="tp-caption tp-resizeme" id="slide-901-layer-6"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['top','top','top','top']" data-voffset="['540','540','470','470']"
                    data-lineheight="['none','none','none','none']" data-width="['800','800','600','300']"
                    data-height="['none','none','none','none']" data-whitespace="['normal','normal','normal','normal']"
                    data-type="text" data-responsive_offset="on" data-frames='[ 
                {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                ]' data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                    style="z-index:13; text-transform:uppercase;">
                    <a routerLink="/about/about1" class="site-button site-btn-effect">Read More</a>
                </div>


                <!-- Border Part -->
                <div class="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-8"
                    data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                    data-height="full" data-whitespace="nowrap" data-visibility="['on','on','off','off']"
                    data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                    data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                    data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                    style="z-index: 10;background-color:rgba(0, 0, 0, 0);border-color:rgb(255,255,255);border-style:solid;border-width:0px 50px 50px 50px;">
                </div>

            </li>

        </ul>
        <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
    </div>
</div>