import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proiectare-consultanta',
  templateUrl: './proiectare-consultanta.component.html',
  styleUrls: ['./proiectare-consultanta.component.css']
})
export class ProiectareConsultantaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "PROIECTARE ȘI CONSULTANȚĂ",
  }

}
