<header class="site-header header-style-1 mobile-sider-drawer-menu">

    <!-- SITE Search -->
    <div id="search-toggle-block">
        <div id="search">
            <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                <div class="input-group">
                    <input class="form-control" value="" name="q" type="search" placeholder="Type to search" />
                    <span class="input-group-append"><button type="button" class="search-btn"><i
                                class="fa fa-search"></i></button></span>
                </div>
            </form>
        </div>
    </div>

    <div class="container header-middle clearfix">
        <div class="my-header">

            <div class="logo-header">
                <div class="logo-header-inner logo-header-one">
                    <a routerLink="/index">
                        <img id="headLogoId" src="assets/images/LogoPaginaSKL/Site_Header.jpg" />
                    </a>
                </div>
            </div>

            <div class="header-info">
                <div class="header-item">
                    <span class="icon-cell"><i class="fa fa-envelope"></i></span>
                    <span>officesklsolution@gmail.com</span>
                </div>
                <div class="header-item">
                    <span class="icon-cell"><i class="fa fa-phone"></i></span>
                    <span>0723 000 585 </span>
                </div>
                <div class="header-item">
                    <span class="icon-cell"><i class="fa fa-phone"></i></span>
                    <span>0740 010 252 </span>
                </div>
                


            </div>
        </div>

    </div>

    <div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">
            <div class="container clearfix">
                <!-- NAV Toggle Button -->
                <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
                    class="navbar-toggler collapsed">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar icon-bar-first"></span>
                    <span class="icon-bar icon-bar-two"></span>
                    <span class="icon-bar icon-bar-three"></span>
                </button>

                <!-- MAIN Vav -->
                <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                    <ul class=" nav navbar-nav">
                        <li><a routerLink="/acasa" [routerLinkActive]="['active-menu']">ACASĂ</a></li>
                        <li><a routerLink="/despre-noi" [routerLinkActive]="['active-menu']">DESPRE NOI</a></li>
                        <li><a routerLink="/servicii" [routerLinkActive]="['active-menu']">SERVICII</a>
                            <ul class="sub-menu">
                                <ng-container *ngFor="let service of services; let i = index;">
                                    <li *ngIf="service.isActive">
                                        <a [routerLink]="service.path">{{ service.title}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                        <li><a routerLink="/proiecte" [routerLinkActive]="['active-menu']">PROIECTE</a>
                            <ul class="sub-menu">
                                <li><a routerLink="/proiecte/categorie/civil-rezidential">CIVIL-REZIDENȚIAL</a>
                                </li>
                                <li><a routerLink="/proiecte/categorie/industrial">INDUSTRIAL</a></li>
                                <li><a routerLink="/proiecte/categorie/comercial">COMERCIAL</a>
                                </li>
                                <li><a routerLink="/proiecte/categorie/eficienta-energetica">EFICIENȚĂ ENERGETICĂ</a>
                                </li>
                            </ul>
                        </li>
                        <li><a routerLink="/cariera" [routerLinkActive]="['active-menu']">CARIERĂ</a>
                            <ul class="sub-menu">
                                <ng-container *ngFor="let job of jobs; let i = index;">
                                    <li *ngIf="job.isActive">
                                        <a [routerLink]="'/cariera/' + i">{{ job.name}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                        <!-- <li><a routerLink="/contact" [routerLinkActive]="['active-menu']">CONTACT</a></li> -->
                    </ul>

                </div>


                <div class="header-nav-request">

                    <!--  class="contact-slide-show" -->
                    <a routerLink="/contact" class="contact-slide-show2">Contact</a>
                </div>
            </div>
        </div>
    </div>



</header>