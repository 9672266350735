<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header1></app-section-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <app-section-projects1></app-section-projects1>


    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>