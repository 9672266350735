import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-error404',
  templateUrl: './page-error404.component.html',
  styleUrls: ['./page-error404.component.css']
})
export class PageError404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
