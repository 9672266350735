import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { PhotoRoute } from 'src/app/core/models/photo-route.model';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.css'],
})
export class GalleryModalComponent
  extends SimpleModalComponent<any, any>
  implements OnInit
{
  photosRoute: PhotoRoute[];
  mainPhoto: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.mainPhoto = this.photosRoute.find(
      (element) => element.isActive
    ).urlImage;
  }

  onPhoto(item: PhotoRoute): void {
    if (!item.isActive) {
      this.photosRoute.forEach((element) => {
        element.isActive = false;
      });
  
      item.isActive = true;
      this.mainPhoto = this.photosRoute.find(
        (element) => element.isActive
      ).urlImage;
  
      // Scroll to the top of the gallery show section
      const galleryShowElement = document.getElementById('gallery-show');
      galleryShowElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  nextPhoto(): void {
    const currentIndex = this.photosRoute.findIndex((photo) => photo.isActive);
    this.photosRoute[currentIndex].isActive = false;
  
    const nextIndex = (currentIndex + 1) % this.photosRoute.length; // Loop to the first
    this.photosRoute[nextIndex].isActive = true;
  
    this.mainPhoto = this.photosRoute[nextIndex].urlImage;
  }
  
  previousPhoto(): void {
    const currentIndex = this.photosRoute.findIndex((photo) => photo.isActive);
    this.photosRoute[currentIndex].isActive = false;
  
    const previousIndex =
      (currentIndex - 1 + this.photosRoute.length) % this.photosRoute.length; // Loop to the last
    this.photosRoute[previousIndex].isActive = true;
  
    this.mainPhoto = this.photosRoute[previousIndex].urlImage;
  }
  
  
}
