import { Component, Input, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/pages/services/services.service';

@Component({
  selector: 'app-section-services1',
  templateUrl: './section-services1.component.html',
  styleUrls: ['./section-services1.component.css']
})
export class SectionServices1Component implements OnInit {

  data = {
    subtitle: "SERVICII",
    description: "SKL Technion Solution integrează la nivel de antreprenoriat soluții și servicii pentru multiple categorii și domenii precum civil, industrial, energetic, fie cu personal propriu, fie cu partenerii noștri direcți oferind servicii complete, profesionale și de calitate. Oferim si câștigăm încredere.",
    services: {}
  }

  constructor(servicesService: ServicesService) {
    this.data.services = servicesService.all;
  }

  ngOnInit(): void {
  }

}
