<div class="widget recent-posts-entry p-a20">
    <div class="text-left m-b30">
        <h3 class="widget-title">Recent Posts</h3>
    </div>                                    
    <div class="section-content">
        <div class="widget-post-bx">
            <div class="widget-post clearfix" *ngFor="let object of data">
                <div class="wt-post-media">
                    <img src="{{object.image}}" alt="">
                </div>
                <div class="wt-post-info">
                    <div class="wt-post-header">
                        <h6 class="post-title"> <a routerLink="/blog/blog-post-right-sidebar">Loft Office With Vintage Decor For Working</a></h6>
                    </div>                                                    
                    <div class="wt-post-meta">
                        <ul>
                            <li class="post-author">{{object.date}}</li>
                            <li class="post-comment">{{object.comments}} Comment</li>
                        </ul>
                    </div>                                            
                </div>
            </div>                                                 
        </div>
    </div>
</div>