import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceDescription, ServicesService } from 'src/app/pages/services/services.service';

@Component({
  selector: 'app-section-services11',
  templateUrl: './section-services11.component.html',
  styleUrls: ['./section-services11.component.css']
})
export class SectionServices11Component implements OnInit {

  services: ServiceDescription[];

  constructor(servicesService: ServicesService, private router: Router) {
    this.services = servicesService.all;
  }

  ngOnInit(): void {
  }

  navigate(path: string) {
    this.router.navigate([path]);

  }
}
