<div class="section-full p-t80 p-b50 bg-white arc2-team-wrapper">
    <div class="container">
        <div class="section-content">
        
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->
                   
        <div class="section-content">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6 m-b30" *ngFor="let object of data.members">
                    <div class="wt-team-arc2 wt-team-arc2-no-border">
                        
                        <div class="wt-media">
                            <img src="{{object.image}}" alt="">
                            <div class="team-social-center">
                                <ul class="team-social-icon">
                                    <li><a href="https://www.google.com/" class="fa fa-google"></a></li>
                                    <li><a href="https://www.facebook.com/" class="fa fa-facebook"></a></li>
                                    <li><a href="https://twitter.com/" class="fa fa-twitter"></a></li>
                                    <li><a href="https://www.linkedin.com/" class="fa fa-linkedin"></a></li>                                                
                                </ul>
                            </div>                                                
                        </div>                                   

                        <div class="wt-info bg-gray-light p-a20">
                            <div class="team-detail  text-center">
                                <h4 class="m-t0">{{object.name}}</h4>
                                <p>{{object.specification}}</p>
                            </div>
                        </div>
                
                    </div>
                </div>
                
            </div>
        </div>      
            
        </div>
    </div>
</div> 