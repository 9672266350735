<div class="section-full p-t80 p-b40">
    <div class="container">
        <div class="section-content">
        
            <div class="wt-separator-two-part">
                <div class="row wt-separator-two-part-row">
                    <div class="col-lg-8 col-md-7 wt-separator-two-part-left">
                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                                <div class="sep-leaf-left"></div>
                                <div>{{data.title}}</div>
                                <div class="sep-leaf-right"></div>
                            </div>
                            <h2>{{data.subtitle}}</h2>
                        </div>
                        <!-- TITLE END-->
                    </div>
                    <div class="col-lg-4 col-md-5 wt-separator-two-part-right text-right">
                        <a routerLink="/services/s-agricultural" class="site-button site-btn-effect">More Detail</a>
                    </div>
                </div>
            </div>                   
        
       
            <div class="row d-flex justify-content-center">
            
                    <div class="col-lg-4 col-md-6 col-sm-12 m-b50" *ngFor="let object of data.services">
                        <div class="service-border-box service-image-box">
                            <div class="wt-box service-box-1 bg-white">
                            
                                <div class="service-box-1-media m-b20">
                                    <img src="{{object.image}}" alt="">
                                </div>
                                
                                <div class="service-box-title title-style-2 site-text-secondry">
                                    <span  class="s-title-one">{{object.title1}}</span>
                                    <span  class="s-title-two">{{object.title2}}</span>     
                                </div>
                                <div class="service-box-content">
                                    <p>{{object.description}}</p>
                                    <a routerLink="/{{object.pageLink}}" class="site-button-link">Read More</a>
                                </div>
                                                                            
                            </div>
                        </div>                     
                    </div>                             
                                                                    
            </div>
        </div>
    </div>
</div>