import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-get-in-touch',
  templateUrl: './section-get-in-touch.component.html',
  styleUrls: ['./section-get-in-touch.component.css']
})
export class SectionGetInTouchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
