import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monotorizare-mentenanta',
  templateUrl: './monotorizare-mentenanta.component.html',
  styleUrls: ['./monotorizare-mentenanta.component.css']
})
export class MonotorizareMentenantaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "MONITORIZARE ȘI MENTENANȚĂ",
  }


}
