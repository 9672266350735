import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProjectsService, ProjectDescription } from 'src/app/pages/services/projects.service';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-section-projects1',
  templateUrl: './section-projects1.component.html',
  styleUrls: ['./section-projects1.component.css']
})
export class SectionProjects1Component implements AfterViewInit {

  projects: ProjectDescription[];
  subscription: Subscription;

  @ViewChild('toate') toate: ElementRef;
  @ViewChild('civile') civile: ElementRef;
  @ViewChild('industriale') industriale: ElementRef;
  @ViewChild('comerciale') comerciale: ElementRef;
  @ViewChild('eficienta') eficienta: ElementRef;


  constructor(
    private projectsService: ProjectsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location) {
    this.projects = this.projectsService.all;
  }

  setCategory(category: string) {
    switch (category) {
      case "civil-rezidential":
        this.civile.nativeElement.click();
        break;
      case "industrial":
        this.industriale.nativeElement.click();
        break;
      case "comercial":
        this.comerciale.nativeElement.click();
        break;
      case "eficienta-energetica":
        this.eficienta.nativeElement.click();
        break;
      default:
        this.toate.nativeElement.click();
        break;
    }
  }

  replaceUrl(path: string) {
    this.location.replaceState(path);
  }

  navigateToProjctDetails(index: number) {
    this.router.navigate(['/proiecte/' + index]);
  }

  ngAfterViewInit(): void {
    this.subscription = this.activeRoute.params.subscribe(params => {
      let cat = params['category'];
      if (cat) {
        setTimeout(() => this.setCategory(cat), 700);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}