import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-about7',
  templateUrl: './section-about7.component.html',
  styleUrls: ['./section-about7.component.css']
})
export class SectionAbout7Component implements OnInit {

  @Input() data: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
