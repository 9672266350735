<div class="section-full p-t80 p-b80 bg-cover overlay-wraper" style="background-image:url(assets/images/oil-gas/bg/bg1.jpg)">
    <div class="overlay-main site-bg-secondry opacity-07"></div>	
    <div class="container">
        <!--TITLE-->
        <div class="section-head center wt-small-separator-outer text-center text-white">
            <div class="wt-small-separator site-text-primary">
                <div class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!--TITLE-->
    </div>
                           
    <div class="section-content container-fluid"> 
    
        <div class="owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery">
        
            <div class="item" *ngFor="let object of data.projects">
                <div class="project-img-effect-1">
                    <img src="{{object.image}}" alt="" />
                    <div class="wt-info">
                        <h3 class="wt-tilte m-tb0"><a routerLink="/projects/project-detail">{{object.title}}</a></h3>
                    </div>
                   <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
                </div>
            </div>                     
            
        </div>

    </div>                                        

</div>