<div class="page-wraper">
                	
    <!-- HEADER START -->
    <app-section-header8></app-section-header8>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START --> 
        <app-section-slider11></app-section-slider11>
        <!-- SLIDER END -->

        <!-- ABOUT ONE SECTION START -->
        <app-section-about7 [data]="about"></app-section-about7>  
        <!-- ABOUT ONE SECTION END -->

        <!-- INTRO SECTION START -->
        <app-section-what-we-do5 [data]="whatWeDo"></app-section-what-we-do5>
        <!-- INTRO SECTION END -->  

        <!-- SERVICES SECTION START -->
        <app-section-services9 [data]="services"></app-section-services9>
        <!-- SERVICES SECTION END -->
        
        <!-- OUR TEAM START -->
        <app-section-team5 [data]="team"></app-section-team5>   
        <!-- OUR TEAM SECTION END -->

        <!-- GET IN TOUCH SECTION START -->
        <app-section-get-in-touch1></app-section-get-in-touch1>   
        <!-- GET IN TOUCH SECTION End -->           

        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients2 [data]="clients"></app-section-clients2>
        <!-- CLIENT LOGO  SECTION End -->

        <!-- LATEST PRJECTS SLIDER START -->
        <app-section-projects9 [data]="projects"></app-section-projects9>   
        <!-- LATEST PRJECTS SLIDER END --> 

        <!-- TESTIMONIALS SECTION START -->
        <app-section-testimonials6 [data]="testimonials"></app-section-testimonials6>
        <!-- TESTIMONIALS  SECTION End -->  
        
        <!-- OUR BLOG START -->
        <app-section-blogs9 [data]="blogs"></app-section-blogs9>
        <!-- OUR BLOG END -->                         

    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer3></app-section-footer3>
    <!-- FOOTER END -->

 </div>

<app-loader></app-loader>
