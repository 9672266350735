<div class="section-full video2-counter-section p-t80 p-b40  overlay-wraper bg-cover bg-no-repeat bg-parallax" data-stellar-background-ratio="0.5" style="background-image:url(assets/images/solar-icon/bg/bg-1.jpg);">
    <div class="overlay-main bg-white opacity-03"></div>                                       

            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END--> 

                <div class="counter2-section-outer-top">
                        <div class="row justify-content-center">
                        
                            <div class="col-lg-6 col-md-12 m-b30">
                            
                                <div class="video-section2-outer mfp-gallery">
                                    <div class="video-section">
                                         <a href="{{data.videoLink}}" class="mfp-video play-now">
                                            <i class="icon fa fa-play"></i>
                                            <span class="ripple"></span>
                                        </a>                                              
                                    </div>	    
                                </div>                                            

                            </div>

                        </div>                            

                </div>  
                
                <div class="counter2-section-outer-bottom">
                    <div class="counter-outer">                            
                        
                        <div class="row justify-content-center">
                                
                            <div class="col-lg-3 col-md-6 col-sm-6 m-b30 " *ngFor="let object of data.counters">
                                <div class="wt-icon-box-wraper center">
                                    <h2 class="counter site-text-primary">{{object.count}}</h2>
                                    <span class="title-style-2">{{object.title}}</span>
                                </div>
                            </div>
                            
                        </div>                            
                    
                    </div>   
                </div>  
                                                                            
           </div>

        </div>