<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header4></app-section-header4>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">
        <!-- SLIDER START -->
        <app-section-slider4></app-section-slider4>
        <!-- SLIDER END -->

        <!-- WHAT WE DO SECTION START -->
        <app-section-what-we-do2 [data]="whatwedo"></app-section-what-we-do2>
        <!-- WHAT WE DO SECTION END -->

        <!-- SERVICES SECTION START -->
        <app-section-services2 [data]="services"></app-section-services2>
        <!-- SERVICES SECTION END -->

        <!-- COUNTER SECTION START -->
        <app-section-counter2 [data]="counter" bgplain="0"></app-section-counter2>
        <!-- COUNTER SECTION  SECTION END -->

        <!-- VISION SECTION START -->
        <app-section-vision1 [data]="vision"></app-section-vision1>
        <!-- VISION SECTION END -->

        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials1 [data]="testimonials"></app-section-testimonials1>
        <!-- TESTIMONIAL SECTION END -->

        <!-- OUR TEAM START -->
        <app-section-team1 [data]="team"></app-section-team1>
        <!-- OUR TEAM SECTION END -->

        <!-- OUR BLOG START -->
        <app-section-blogs3 [data]="blogs" wide="1"></app-section-blogs3>
        <!-- OUR BLOG END -->

        <!-- GALLERY SECTION START -->
        <app-section-gallery1 [data]="caseStudies"></app-section-gallery1>
        <!-- GALLERY SECTION END -->

        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>