<div class="section-full p-t80 p-b50 bg-gray">
                    
    <div class="container">


        <!-- TITLE START-->
        <div class="section-head center  wt-small-separator-outer">
            <div class="wt-small-separator site-text-primary">
                <div>{{data.title}}</div>
            </div>
            <h2 class="wt-title">{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->



        <div class="s-section">
            <div class="row justify-content-center">
                <!-- COLUMNS 1 --> 
                <div class="col-lg-4 col-md-6  col-sm-6 m-b30" *ngFor="let object of data.features">
                    <div class="imghvr1 text-center">
                        <img src="{{object.image}}">
                        <figcaption>
                            <div class="imghvr1-content">      
                                <h4 class="wt-title"><a routerLink="/services/service-detail">{{object.title}}</a></h4>
                                <p>{{object.description}}</p>
                                <a routerLink="/services/service-detail" class="site-button-link site-text-primary">Read More</a>
                            </div>
                        </figcaption>
                    </div>
                </div>

            </div>
        </div>                  
    </div>

</div>