import { Component, OnInit } from '@angular/core';
import { ServiceDescription, ServicesService } from 'src/app/pages/services/services.service';

@Component({
  selector: 'app-section-footer1',
  templateUrl: './section-footer1.component.html',
  styleUrls: ['./section-footer1.component.css']
})
export class SectionFooter1Component implements OnInit {

  services: ServiceDescription[];

  constructor(private servicesService: ServicesService) {
    this.services = this.servicesService.all;

  }

  ngOnInit(): void {

  }

}
