<div class="section-full p-t80 p-b80 bg-gray">
                                   
    <div class="section-content container-fluid"> 
    
        <div class="owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery">
        
            <div class="item" *ngFor="let object of data">
                <div class="project-img-effect-1">
                    <img src="{{object.image}}" alt="" />
                    <div class="wt-info">
                        <h3 class="wt-tilte m-b10 m-t0">{{object.title}}</h3>
                        <p>{{object.description}}</p>
                        <a routerLink="/projects/project-detail" class="site-button-link">Read More</a>
                    </div>
                   <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
                </div>
            </div>
            
        </div>

    </div>                                        

</div> 