<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- GALLERY SECTION START -->
    <app-section-gallery3
      [data]="selectedProject.images"
    ></app-section-gallery3>
    <!-- GALLERY SECTION END -->

    <div class="section-full p-t20 p-b50">
      <div class="container">
        <div class="row d-flex justify-content-center flex-wrap">
          <div class="col-lg-4 col-md-12 m-b30">
            <div class="project-detail-left bg-gray p-a30">
              <div
                class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray"
              >
                <div class="icon-sm m-b15">
                  <span class="icon-cell site-text-primary"
                    ><i class="fa fa-tachometer"></i
                  ></span>
                </div>
                <div class="icon-content">
                  <h3 class="wt-tilte m-b5">Proiect</h3>
                  <p>{{ selectedProject.title }}</p>
                </div>
              </div>

              <div
                class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray"
              >
                <div class="icon-sm m-b15">
                  <span class="icon-cell site-text-primary"
                    ><i class="fa fa-list-alt"></i
                  ></span>
                </div>
                <div class="icon-content">
                  <h3 class="wt-tilte m-b5">Categorie proiect</h3>
                  <p>{{ selectedProject.category_name }}</p>
                </div>
              </div>

              <div
                class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray"
              >
                <div class="icon-sm m-b15">
                  <span class="icon-cell site-text-primary"
                    ><i class="fa fa-calendar"></i
                  ></span>
                </div>
                <div class="icon-content">
                  <h3 class="wt-tilte m-b5">Timp</h3>
                  <p>{{ selectedProject.time }}</p>
                </div>
              </div>

              <!-- <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-tags"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Status</h3>
                                    <p>{{details.status}}</p>
                                </div>
                            </div> -->
              <div
                (click)="onGallery()"
                *ngIf="selectedProject?.gallery?.length > 1"
                class="wt-icon-box-wraper left gallery m-b15 p-b15 bdr-1 bdr-bottom bdr-gray"
              >
                <div class="icon-sm m-b15">
                  <span class="icon-cell site-text-primary"
                    ><i class="fa fa-image"></i
                  ></span>
                </div>
                <div class="icon-content">
                  <h3 class="wt-tilte m-b5">Galerie</h3>
                  <p class="gallery__text">Click aici sa vezi galeria</p>
                </div>
              </div>

              <div class="wt-icon-box-wraper left">
                <div class="icon-sm m-b15">
                  <span class="icon-cell site-text-primary"
                    ><i class="fa fa-user"></i
                  ></span>
                </div>
                <div class="icon-content">
                  <h3 class="wt-tilte m-b5">Client</h3>
                  <p>{{ selectedProject.client }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-12 m-b30">
            <div class="project-detail-right text-justify">
              <h3 class="m-t0">Descriere</h3>
              <p [innerHtml]="selectedProject.description"></p>

              <h3 class="m-t0">Soluție</h3>
              <p [innerHtml]="selectedProject.solution"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>
