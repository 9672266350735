<div class="section-full p-t60 p-b60 bg-gray-light">
    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <h1 class="site-text-primary">{{data.title}}</h1>
            <p><span class="site-text-primary">SKL Technion Solution</span>{{data.description}}</p>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
            <div class="owl-carousel services-style-new owl-btn-vertical-center">
                <!-- COLUMNS 1 -->
                <div class="item" *ngFor="let service of data.services; let i=index;">
                    <div class="wt-box service-box-1-new bg-white">
<!-- de -->

                        <div class="service-box-1-new__top">
                            <!-- ICON -->
                            <div class="wt-icon-box-wraper p-b20">
                                <div class="wt-icon-box-md site-bg-primary">
                                    <span class="icon-cell service-icon service-icon-home"><img src="{{service.icon}}" alt=""></span>
                                </div>
                                <div class="wt-icon-number-new"><span>{{ i+1 | number : '2.0'}}</span></div>
                            </div>

                            <!-- TITLE -->
                            <div class="service-box-title-new title-style-2">
                                <span class="s-title-one">{{service.title}}</span>
                            </div>

                            <!-- DESCRIPTION (LIST) -->
                            <div class="service-box-content-new">
                                <!-- <p>{{service.description}}</p> -->
                                <ul class="m-b0">
                                    <li class="m-l20" *ngFor="let item of service.list">{{item}}</li>
                                </ul>
                            </div>
                        </div>

                        <!-- DETAILS LINK  -->
                        <div class="service-box-new-link service-box-new-link-border">
                            <a [routerLink]="service.path"
                                class="site-button-link site-text-primary underlined">DETALII</a>
                        </div>

                    </div>
                </div>


            </div>

        </div>

    </div>
</div>