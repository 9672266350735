<div class="section-full bg-gray p-t90 p-b50">
    <div class="container">
        <div class="section-content client-logo2 ">
            <div class="row justify-content-center">
                <div class=" col-lg-3 col-md-4 col-sm-4 col-6" *ngFor="let object of data; let i = index;">
                    <a routerLink="/about/about1" class="wt-img-effect client-logo2-media {{i==0 ? 'active' : ''}}">
                        <img src="{{object}}" alt=""> 
                    </a>
                </div>
            
            </div>
        </div>
    </div>  
</div>