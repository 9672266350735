<div class="modal-content" id="gallery-show">
  <div class="modal-header">
    <span>Galerie</span>
    <button class="modal-close" (click)="close()">X</button>
  </div>
  <div class="modal-body">
    <div class="gallery">
      <div class="gallery__show">
        <button class="gallery__arrow gallery__arrow--left" (click)="previousPhoto()">&#9664;</button>
        <img [src]="mainPhoto" alt="" />
        <button class="gallery__arrow gallery__arrow--right" (click)="nextPhoto()">&#9654;</button>
      </div>
      <div class="gallery__slider" *ngIf="photosRoute.length > 1">
        <div
          class="item gallery__slider__item"
          *ngFor="let item of photosRoute"
          [class.gallery__slider__item--active]="item.isActive"
        >
          <img (click)="onPhoto(item)" [src]="item.urlImage" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="close()" class="modal-button">Închide</div>
  </div>
</div>