<div class="section-full  p-t80 p-b50 bg-black project-gallery-style1-wrapper" style="background-image:url(assets/images/ui-9/background/bg-map2.png)">
    <div class="container">

        <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
                <div class="col-lg-8 col-md-7 wt-separator-two-part-left">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer text-white">
                        <div class="wt-small-separator site-text-white">
                            <div class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                    </div>
                    <!-- TITLE END-->
                </div>
                <div class="col-lg-4 col-md-5 wt-separator-two-part-right text-right">
                    <a routerLink="/projects/project-grid" class="site-button site-btn-effect">More Detail</a>
                </div>
            </div>
        </div>

     </div>
        <div class="section-content"> 
            <div class="project-gallery-block-outer m-b30">
                <div class="container">

                    <div class="project-gallery-style1">
                        <div class="owl-carousel project-gallery-one owl-btn-vertical-center ">
                        
                            <!-- COLUMNS 1 --> 
                            <div class="item" *ngFor="let object of data.projects">
                                <div class="project-box-style1">
                                    <div class="project-content">
                                        <div class="project-title">
                                            {{object.title1}} 
                                        </div>
                                        <h3 class="project-title-large"><a routerLink="/project-single">{{object.title2}}</a></h3>
                                    </div>
                                    <div class="project-media">
                                        <img src="{{object.image}}" alt="">
                                    </div>
                                    <div class="project-view">
                                        <a class="elem pic-long project-view-btn" href="{{object.image}}" title="{{object.title1}}" 
                                        data-lcl-txt="{{object.subtitle}}m" data-lcl-author="{{object.author}}" data-lcl-thumb="{{object.image}}">
                                        <i></i>    
                                        </a> 
                                    </div>                                    
                                </div>
                            </div>          
                    
                        </div>
                    </div>
                
                </div>
            </div>
        </div>

    </div>