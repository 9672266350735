import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-quality1',
  templateUrl: './section-quality1.component.html',
  styleUrls: ['./section-quality1.component.css']
})
export class SectionQuality1Component implements OnInit {

  data = {
    title: "SKL Technion Solution",
    subtitle: "Integritate. Calitate. Încredere.",
    counters: [
      {
        count: "10",
        title: "Certificări"
      },
      {
        count: "25",
        title: "Angajați"
      },
      {
        count: "255",
        title: "Clienți"
      },
      {
        count: "285",
        title: "Proiecte complete"
      },
    ],
  }

  constructor() { }

  ngOnInit(): void {
  }

}
