<div class="section-full p-t80 p-b50 bg-gray-light">
    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END--> 

       <div class="section-content">
            <div class="icon-circle-box-outer bg-white p-a30 m-b30">
                <div class="row justify-content-center ">
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <div class="icon-circle-box v-icon-effect">
                            <div class="wt-icon-box-wraper center">
                                <div class="icon-md icon-circle">
                                    <span class="icon-cell">
                                        <i class="flaticon-call v-icon "></i>
                                    </span>
                                </div>
                                <div class="icon-content relative">
                                    <h4 class="wt-tilte">Have a question? call us now</h4>
                                    <span class="icon-count-number">1</span>
                                    <p>{{data.call}}</p>
                                </div>
                            </div>
                        </div>                          
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="icon-circle-box v-icon-effect">
                            <div class="wt-icon-box-wraper center">
                                <div class="icon-md icon-circle">
                                    <span class="icon-cell">
                                        <i class="flaticon-mail v-icon "></i>
                                    </span>
                                </div>
                                <div class="icon-content relative">
                                    <h4 class="wt-tilte">Need support? Drop us an Email</h4>
                                        <span class="icon-count-number">2</span>
                                    <p>{{data.support}}</p>
                                </div>
                            </div>
                        </div>                          
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="icon-circle-box  v-icon-effect">
                            <div class="wt-icon-box-wraper center">
                                <div class="icon-md icon-circle">
                                    <span class="icon-cell">
                                        <i class="flaticon-history v-icon "></i>
                                    </span>
                                </div>
                                <div class="icon-content relative">
                                    <h4 class="wt-tilte">We are open on</h4>
                                    <span class="icon-count-number">3</span>
                                    <p><span >{{data.openTime1}}</span><span >{{data.openTime2}}</span></p>
                                    
                                </div>
                            </div>
                        </div>                          
                    </div>  

                </div>                          
            </div>                                                            
       </div>
   
   </div>
</div>