<div class="section-full p-t80 p-b50 bg-no-repeat bg-center bg-white">
    <div class="about-section-three">
        <div class="container">
            <div class="section-content">                 
                <div class="row justify-content-center d-flex">
                
                    <div class="col-lg-6 col-md-12 m-b30">
                        <div class="about-max-three">
                            <div class="about-max-three-media"><img src="{{data.image}}" alt="" ></div>
                            <div class="about-three">
                                <div class="about-year">
                                    <div class="about-year-info">
                                        <span class="icon-md p-t10">
                                            <i class="{{data.imageIcon}}"></i>
                                        </span>
                                        <h3 class="wt-tilte">{{data.imageTag}}</h3>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div> 
                                        
                    <div class="col-lg-6 col-md-12 m-b30">
                        <div class="about-section-three-right">
                            <!-- TITLE START-->
                            <div class="left wt-small-separator-outer">
                                <div class="wt-small-separator site-text-primary">
                                    <div  class="sep-leaf-left"></div>
                                    <div>{{data.title}}</div>
                                    <div  class="sep-leaf-right"></div>
                                </div>
                                <h2>{{data.subtitle}}</h2>

                            </div>                                    
                            <!-- TITLE END-->

                        
                             <!-- Accordian -->
                            <div class="wt-accordion acc-bg-gray m-b50 about-section-three-acc" id="accordion">
                                
                              <div class="panel wt-panel" *ngFor="let object of data.specifications; let i = index">
                                  <div class="acod-head {{ i == 0 ? 'acc-actives' : '' }}" id="heading{{i}}">
                                    <h5 class="{{ i == 0 ? 'acod-title' : 'mb-0' }}">
                                      <a class="{{ i == 0 ? '' : 'collapsed' }}" data-toggle="collapse" href="#collapse{{i}}" aria-expanded="{{ i == 0 ? 'true' : 'false' }}" aria-controls="collapse{{i}}">
                                        {{object.title}}
                                        <span class="indicator"><i class="fa"></i></span>
                                      </a>
                                    </h5>
                                  </div>
                              
                                  <div id="collapse{{i}}" class="collapse {{ i == 0 ? 'show' : '' }}" aria-labelledby="heading{{i}}" data-parent="#accordion">
                                    <div class="acod-content p-tb15">
                                        {{object.description}}
                                    </div>
                                  </div>
                                </div>

                            </div>                                    

                            <div class="ab-three-info d-flex justify-content-between">
                                <div class="welcom-btn-position m-t20"><a routerLink="/about/about1" class="site-button site-btn-effect">More About</a></div>
                             <!-- <img src="{{data.sign}}" alt="">-->  
                            </div>
                                                        
                        </div>
                                                                        

                    </div>

                    
            </div>
        </div> 
    </div>
    </div>
</div> 