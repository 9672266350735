<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->
        
        <!-- OUR PLAN US START -->
        <div class="section-full p-t80 p-b50">
            <div class="container">
            
                <!-- TITLE START-->
                    <div class="section-head center wt-small-separator-outer text-center">
                        <div class="wt-small-separator site-text-primary">
                            <div class="sep-leaf-left"></div>
                            <div>{{plans.title}}</div>
                            <div class="sep-leaf-right"></div>
                        </div>
                        <h2>{{plans.subtitle}}</h2>
                    </div>
                <!-- TITLE END-->


                <div class="section-content">
                    <div class="pricingtable-row p-lr15 no-col-gap">
                        <div class="row d-flex justify-content-center">
                        
    
                            <div class="col-lg-4 col-md-6 col-sm-12 m-b30" *ngFor="let object of plans.pricing; let i=index;">
                                <div class="pricingtable-wrapper {{i!=1 ? 'bg-gray' : ''}}">
                                    <div class="pricingtable-inner {{i==1 ? 'pricingtable-highlight' : ''}}">
                                        <div class="pricing-table-top-section">
                                            <div class="pricingtable-price">
                                                <span class="pricingtable-type">Installation</span>                                                    
                                                <h2 class="pricingtable-bx"><sup class="pricingtable-sign">$</sup>{{object.price}}</h2>
                                            </div>
                                            
                                            <div class="pricingtable-title">
                                                <h2 class="title-style-2">{{object.plan}} Plan</h2>
                                            </div>                                                    
                                        </div>
                                        
                                        <ul class="pricingtable-features">
                                            <li>{{object.description}} </li>
                                        </ul>
                                        
                                        <div class="pricingtable-footer">
                                            <a routerLink="/about/about1" class="{{i==1 ? 'site-button-secondry' : 'site-button'}} site-btn-effect">Select</a>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>   

            </div>
        </div>   
        <!-- OUR PLAN US SECTION END --> 
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
