import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/pages/services/jobs.service';
import { ProjectsService } from 'src/app/pages/services/projects.service';
import { ServicesService } from 'src/app/pages/services/services.service';

@Component({
  selector: 'app-section-header1',
  templateUrl: './section-header1.component.html',
  styleUrls: ['./section-header1.component.css']
})
export class SectionHeader1Component implements OnInit {

  services: any;
  projects: any;
  jobs: any;

  constructor(
    private servicesService: ServicesService,
    private projectsService: ProjectsService,
    private jobsService: JobsService,
  ) {
    this.services = this.servicesService.all;
    this.projects = this.projectsService.all;
    this.jobs = this.jobsService.all;
  }

  ngOnInit(): void {
  }

}
