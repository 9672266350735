<div class="footer-fixed">
    <div class="page-wraper">

        <!-- HEADER START -->
        <app-section-header6></app-section-header6>
        <!-- HEADER END -->

        <!-- CONTENT START -->
        <div class="page-content">

            <!-- SLIDER START -->
            <app-section-slider6></app-section-slider6>
            <!-- SLIDER END -->

            <!-- TOP HALF SECTION START -->
            <app-section-services4 [data]="services"></app-section-services4>
            <!-- TOP HALF SECTION END -->

            <!-- PROJECTS SECTION START -->
            <app-section-projects10></app-section-projects10>
            <!-- PROJECTS SECTION  SECTION END -->

            <!-- ABOUT SECTION START -->
            <app-section-about3 [data]="about"></app-section-about3>
            <!-- ABOUT SECTION  SECTION END -->

            <!-- SERVICES SECTION START -->
            <app-section-how-we-work1 [data]="howWeWork"></app-section-how-we-work1>
            <!-- SERVICES SECTION END -->

            <!-- VIDEO SECTION START -->
            <app-section-video4 [data]="video"></app-section-video4>
            <!-- VIDEO SECTION  SECTION END -->

            <!-- OUR BLOG START -->
            <app-section-blogs5 [data]="blogs"></app-section-blogs5>
            <!-- OUR BLOG END -->

            <!-- CLIENT LOGO SECTION START -->
            <app-section-clients1></app-section-clients1>
            <!-- CLIENT LOGO  SECTION End -->

            <!-- OUR PLAN US START -->
            <app-section-plans2 [data]="plans"></app-section-plans2>
            <!-- OUR PLAN US SECTION END -->

        </div>
        <!-- CONTENT END -->

        <!-- FOOTER START -->
        <app-section-footer2></app-section-footer2>
        <!-- FOOTER END -->

    </div>
</div>

<app-loader></app-loader>