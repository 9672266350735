import { Component, OnInit } from '@angular/core';
import { JobDescription, JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-page-cariera',
  templateUrl: './cariera.component.html',
  styleUrls: ['./cariera.component.css']
})
export class CarieraComponent implements OnInit {
  jobs: JobDescription[];

  constructor(private jobsService: JobsService) {
    this.jobs = this.jobsService.all;
  }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/8.jpg",
    title: "CARIERĂ",
    // crumb: "Detalii job"
  }


}
