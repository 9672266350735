<div class="section-full  p-t80  bg-white">
    <div class="container">
    
        <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
                <div class="col-lg-8 col-md-7 wt-separator-two-part-left">
                    <!-- TITLE START-->
                    <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                    </div>
                    <!-- TITLE END-->
                </div>
                <div class="col-lg-4 col-md-5 wt-separator-two-part-right text-right">
                    <a routerLink="/blog/blog-grid" class="site-button site-btn-effect">More Detail</a>
                </div>
            </div>
        </div>

        <!-- BLOG SECTION START -->
        <div class="section-content">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-12 m-b30" *ngFor="let object of data.articles">
                    <!--Block one-->
                    <div class="blog-post date-style-2">
                        <div class="wt-post-media wt-img-effect zoom-slow">
                            <a routerLink="/blog/blog-post-right-sidebar"><img src="{{object.image}}" alt=""></a>
                        </div>
                        <div class="wt-post-info bg-white p-t30">
                            <div class="wt-post-meta ">
                                <ul>
                                    <li class="post-category"><span>{{object.type}}</span> </li>
                                    <li class="post-date">{{object.date}}</li>
                                    <li class="post-comment">{{object.comments}} Comment</li>
                                </ul>
                            </div>                                 
                            <div class="wt-post-title ">
                                <h3 class="post-title">{{object.title}}</h3>
                            </div>
                            <div class="wt-post-readmore ">
                                <a routerLink="/blog/blog-post-right-sidebar" class="site-button-link black">Read More</a>
                            </div>                                        
                       </div>                                
                    </div>
                </div>                                                  
                                            
            </div>
        </div>
    </div>
    
 </div> 