<div class="section-full welcome-section-outer">
    <div class="welcome-section-top bg-white p-t100 p-b100">
        <div class="container">
            <div class="row d-flex justify-content-center">

                <!-- IMAGES -->
                <div class="col-lg-6 col-md-12 m-b30">
                    <div class="img-colarge2">
                        <div class="colarge-2 slide-right"><img src="{{data.image1}}" alt=""></div>
                        <div class="colarge-2-1"><img src="{{data.image2}}" alt=""></div>
                        <div class="since-year-outer2">
                            <div class="since-year2"><span>Din</span><strong>{{data.since}}</strong></div>
                        </div>
                    </div>
                </div>

                <!-- TEXT -->
                <div class="col-lg-6 col-md-12 m-b30">
                    <div class="welcom-to-section">
                        <h1 class="site-text-primary">{{data.title}}</h1>
                        <p class="text-justify indent-paragraph" [innerHtml]="data.description"></p>
                        <div class="welcom-to-section-bottom d-flex justify-content-between">
                            <div class="welcom-btn-position"><a routerLink="/despre-noi"
                                    class="site-button-secondry site-btn-effect">AFLĂ MAI MULT</a></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>