<div class="slider-outer2 bg-white">
    <div class="mid-slider">
        <div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="typewriter-effect" data-source="gallery">
            <div id="welcome_two" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.1">                
                <ul>
            
                    <!-- SLIDE 1 -->
                    <li data-index="rs-901"
                    data-transition="slidingoverlayhorizontal" 
                    data-slotamount="default" 
                    data-hideafterloop="0" 
                    data-hideslideonmobile="off"  
                    data-easein="default" 
                    data-easeout="default" 
                    data-masterspeed="default"  
                    data-thumb="assets/images/home-11/slider/slide1.jpg"  
                    data-rotate="0"  
                    data-saveperformance="off"  
                    data-title="Slide" data-param1="" 
                    data-description="">
                        <!-- MAIN IMAGE -->
                        <img src="assets/images/home-11/slider/slide1.jpg" center bottom data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina/>
                        <!-- LAYERS -->
                    
                        <!-- LAYER NR. 1 [ for overlay ] -->
                        <div class="tp-caption tp-shape tp-shapewrapper " 
                        id="slide-901-layer-0" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
                        </div>
                    
                        <!-- LAYER NR. 2 [ Small Title ] -->
                        <div class="tp-caption   tp-resizeme site-text-primary" 
                        id="slide-901-layer-2" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','34','30']" 
                        data-y="['top','middle','middle','middle']" data-voffset="['180','-80','-80','-60']"   
                        data-fontsize="['24','24','24','20']"
                        data-lineheight="['24','24','24','20']"
                        data-width="['700','700','96%','96%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        white-space: normal; 
                        font-weight: 700;
                        font-family: 'Exo', sans-serif;">We Make Sure</div>
                                                    
                        <!-- LAYER NR. 3 [ Large Title ] -->
                        <div class="tp-caption   tp-resizeme" 
                        id="slide-901-layer-3" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['top','middle','middle','middle']" data-voffset="['230','20','0','0']"  
                        data-fontsize="['56','56','46','30']"
                        data-lineheight="['66','66','48','38']"
                        data-width="['600','700','96%','96%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        white-space: normal; 
                        font-weight: 900;
                        color:#fff;
                        border-width:0px; font-family: 'Exo', sans-serif; text-transform:uppercase;">Build Everything You Needs</div>
                    
                        <!-- LAYER NR. 4 [ for paragraph] -->
                        <div class="tp-caption  tp-resizeme" 
                        id="slide-901-layer-4" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['50','130','100','80']"  
                        data-fontsize="['16','16','20','14']"
                        data-lineheight="['30','30','30','22']"
                        data-width="['600','600','90%','90%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on"
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        font-weight: 500; 
                        color:#fff;
                        border-width:0px;font-family: 'Exo', sans-serif;">
                        We only hire great people who strive to push their ideas into fruition by outmuscling and outhustling the competition.
                        </div>
                    
                        <!-- LAYER NR. 5 [ for see all service botton ] -->
                        <div class="tp-caption tp-resizeme" 	
                        id="slide-901-layer-5"						
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['150','230','180','180']" 
                        data-lineheight="['none','none','none','none']"
                        data-width="['200','200','200','200']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        
                        data-type="text" 
                        data-responsive_offset="on"
                        data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        
                        style="z-index:13; text-transform:uppercase;">
                        <a routerLink="/about/about1" class="site-button btn-hover-animation"><i class="flaticon-right"></i>More About</a>
                        </div>
                                                

                    </li>

                    <!-- SLIDE 2 -->
                    <li data-index="rs-902"
                    data-transition="slidingoverlayhorizontal" 
                    data-slotamount="default" 
                    data-hideafterloop="0" 
                    data-hideslideonmobile="off"  
                    data-easein="default" 
                    data-easeout="default" 
                    data-masterspeed="default"  
                    data-thumb="assets/images/home-11/slider/slide2.jpg"  
                    data-rotate="0"  
                    data-saveperformance="off"  
                    data-title="Slide" data-param1="" 
                    data-description="">
                        <!-- MAIN IMAGE -->
                        <img src="assets/images/home-11/slider/slide2.jpg"  alt=""  data-bgposition="center bottom" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina/>
                        <!-- LAYERS -->
                    
                        <!-- LAYER NR. 1 [ for overlay ] -->
                        <div class="tp-caption tp-shape tp-shapewrapper " 
                        id="slide-902-layer-0" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape" 
                        data-basealign="slide" 
                        data-responsive_offset="off" 
                        data-responsive="off"
                        data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);border-color:rgba(0, 0, 0, 0);border-width:0px;"> 
                        </div>
                    
                        <!-- LAYER NR. 2 [ Small Title ] -->
                        <div class="tp-caption   tp-resizeme site-text-primary" 
                        id="slide-902-layer-2" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','34','30']" 
                        data-y="['top','middle','middle','middle']" data-voffset="['180','-80','-80','-60']"   
                        data-fontsize="['24','24','24','20']"
                        data-lineheight="['24','24','24','20']"
                        data-width="['700','700','96%','96%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        white-space: normal; 
                        font-weight: 700;
                        font-family: 'Exo', sans-serif;">We Make Sure</div>
                                                    
                        <!-- LAYER NR. 3 [ Large Title ] -->
                        <div class="tp-caption   tp-resizeme" 
                        id="slide-902-layer-3" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['top','middle','middle','middle']" data-voffset="['230','20','0','0']"  
                        data-fontsize="['56','56','46','30']"
                        data-lineheight="['66','66','48','38']"
                        data-width="['600','700','96%','96%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        white-space: normal; 
                        font-weight: 900;
                        color:#fff;
                        border-width:0px; font-family: 'Exo', sans-serif; text-transform:uppercase;">We will build your dream home.</div>
                    
                        <!-- LAYER NR. 4 [ for paragraph] -->
                        <div class="tp-caption  tp-resizeme" 
                        id="slide-902-layer-4" 
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['50','130','100','80']"  
                        data-fontsize="['16','16','20','14']"
                        data-lineheight="['30','30','30','22']"
                        data-width="['600','600','90%','90%']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                    
                        data-type="text" 
                        data-responsive_offset="on"
                        data-frames='[
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                    
                        style="z-index: 13; 
                        font-weight: 500; 
                        color:#fff;
                        border-width:0px;font-family: 'Exo', sans-serif;">
                        We only hire great people who strive to push their ideas into fruition by outmuscling and outhustling the competition.
                        </div>
                    
                        <!-- LAYER NR. 5 [ for see all service botton ] -->
                        <div class="tp-caption tp-resizeme" 	
                        id="slide-902-layer-5"						
                        data-x="['left','left','left','left']" data-hoffset="['30','120','30','30']" 
                        data-y="['middle','middle','middle','middle']" data-voffset="['150','230','180','180']" 
                        data-lineheight="['none','none','none','none']"
                        data-width="['200','200','200','200']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        
                        data-type="text" 
                        data-responsive_offset="on"
                        data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        
                        style="z-index:13; text-transform:uppercase;">
                        <a routerLink="/about/about1" class="site-button btn-hover-animation"><i class="flaticon-right"></i>More About</a>
                        </div>
                                                
                    </li>                                
              
                    
                </ul>
                <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>	
            </div>
        </div>
    </div>
</div>