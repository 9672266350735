<div class="section-full video2-counter-section p-t80 p-b40  overlay-wraper bg-cover bg-no-repeat bg-parallax" data-stellar-background-ratio="0.5" style="background-image:url(assets/images/background/bg3.jpg);">
    <div class="overlay-main bg-white opacity-04"></div>                                       

            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END--> 

                <div class="counter2-section-outer-top">
           
                        
                        <div class="row justify-content-center">
                                
                            <div class="col-lg-5 col-md-12 m-b30 ">
                                <div class="video2-section-info site-bg-secondry site-text-white">
                                    <h3 class="wt-tilte site-text-primary">{{data.description.title}}</h3>
                                    <p>{{data.description.subtitle}}</p>
                                    <a routerLink="/services/s-power-energy" class="site-button site-btn-effect">Read More</a>
                                </div>
                            </div>
                            
                            <div class="col-lg-7 col-md-12 m-b30">
                            
                                <div class="video-section2-outer mfp-gallery">
                                    <div class="video-section">
                                         <a href="{{data.videoLink}}" class="mfp-video play-now">
                                            <i class="icon fa fa-play"></i>
                                            <span class="ripple"></span>
                                        </a>                                              
                                    </div>	    
                                </div>                                            
                                <div class="video-section-blockquote2">
                                    <i class="fa fa-quote-left"></i>
                                    <span class="q-author-detail site-text-secondry title-style-2">{{data.quote}}</span>
                                    <div class="q-author-name site-text-secondry title-style-2">{{data.quoteBy}}</div>
                                </div>
                                
                            </div>
                            
                            

                        </div>                            

                </div>  
                                                                            
           </div>

        </div>