import { Component, OnInit } from '@angular/core';
import { GalleryModalComponent } from 'src/app/shared/gallery-modal/gallery-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-sisteme-antiincendiu',
  templateUrl: './sisteme-antiincendiu.component.html',
  styleUrls: ['./sisteme-antiincendiu.component.css'],
})
export class SistemeAntiincendiuComponent implements OnInit {
  constructor(private simpleModalService: SimpleModalService) {}

  ngOnInit(): void {}

  banner = {
    title: 'SISTEME ANTIINCENDIU',
  };

  openPhoto(url: string): void {
    console.log(url)
    this.simpleModalService
      .addModal(GalleryModalComponent, {
        photosRoute: [{ isActive: true, urlImage: url }],
      })
      .subscribe((modalResponse) => {});
  }
}
