<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header2></app-section-header2>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ABOUT SECTION START -->
        <app-section-about2></app-section-about2>
        <!-- ABOUT SECTION  SECTION END -->

        <!-- ALL SERVICES START -->
        <app-section-services11></app-section-services11>
        <!-- ALL SERVICES SECTION END -->

        <!-- VIDEO SECTION START -->
        <app-section-video6 [data]="video"></app-section-video6>
        <!-- VIDEO SECTION  SECTION END -->

        <!-- OUR TEAM START -->
        <app-section-team2 [data]="team" bgcover="0" grayBox="0"></app-section-team2>
        <!-- OUR TEAM SECTION END -->

        <!-- CLIENT LOGO SECTION START -->
        <app-section-clients1></app-section-clients1>
        <!-- CLIENT LOGO  SECTION End -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>