<header class="site-header header-style-11  mobile-sider-drawer-menu">
    <div class="header-style-11-content">
         <div class="header-middle-wraper">     
            <div class="container">
                <div class="header-middle d-flex justify-content-between align-items-center">
                    <div class="logo-header">
                        <div class="logo-header-inner logo-header-one">
                            <a routerLink="/index">
                            <img id="headLogoId" src="assets/images/logo-dark.png" alt="">
                            </a>
                        </div>
                    </div>
                    
                    <div class="header-info-wraper">
                        
                        <div class="header-info">
                            <ul>
                                <li>
                                    <div class="wt-icon-box-wraper  left">
                                        <div class="wt-icon-box-xs site-bg-primary radius">
                                            <span class="icon-cell site-text-white"><i class="fa fa-map-marker"></i></span>
                                        </div>
                                        <div class="icon-content">
                                            <p>Maxvilla , Tx USA</p>
                                            <h4 class="wt-tilte">121 King Street</h4>
                                        </div>
                                    </div>
                                </li> 

                                <li>
                                    <div class="wt-icon-box-wraper  left">
                                        <div class="wt-icon-box-xs site-bg-primary radius">
                                            <span class="icon-cell site-text-white"><i class="fa fa-envelope"></i></span>
                                        </div>
                                        <div class="icon-content">
                                            <p>Send Us Email</p>
                                            <h4 class="wt-tilte">support@max.com</h4>
                                        </div>
                                    </div>
                                </li>                                
                                
                                <li>
                                    <div class="wt-icon-box-wraper left">
                                        <div class="wt-icon-box-xs site-bg-primary radius">
                                            <span class="icon-cell site-text-white"><i class="fa fa-phone"></i></span>
                                        </div>
                                        <div class="icon-content">
                                            <p>Get Quick Support</p>
                                            <h4 class="wt-tilte">246-899-4600</h4>
                                        </div>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        <div class="header-bottom"> 
            <div class="sticky-header main-bar-wraper  navbar-expand-lg">
            
                <div class="main-bar header-bottom">
                    <div class="container-block clearfix">

                        <div class="navigation-bar">
                            <!-- NAV Toggle Button -->
                            <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar icon-bar-first"></span>
                                <span class="icon-bar icon-bar-two"></span>
                                <span class="icon-bar icon-bar-three"></span>
                            </button> 
    
                            <!-- MAIN Vav -->
                            <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-between">
                        
                                <ul class=" nav navbar-nav">
                                    <li><a href="javascript:void(0);">ACASĂ</a>
                                        <ul class="sub-menu">
                                                    <li><a routerLink="/index">Home Industries</a></li>                                        
                                                    <li><a routerLink="/index2">Home Factory</a></li>
                                                    <li><a routerLink="/index3">Home Construction</a></li>
                                                    <li><a routerLink="/index4">Home Transport</a></li>
                                                    <li><a routerLink="/index5">Home Agriculture</a></li>
                                                    <li><a routerLink="/index6">Home Solar Energy</a></li>
                                                    <li><a routerLink="/index7">Home Oil/Gas Plant</a></li>
                                                    <li><a routerLink="/index8">Home Page 8</a></li>
                                                    <li><a routerLink="/index9">Home Industries 9</a></li>
                                                    <li><a routerLink="/index10">Home Factory 10</a></li>
                                                    <li><a routerLink="/index11">Home Construction 11 <span class="new_blink">New</span></a></li>
                                                    <li><a routerLink="/index12">Air Conditioning Repair 12 <span class="new_blink">New</span></a></li>                                            
                                        </ul>                                 
                                    </li>
                                                                       
                                    <li><a href="javascript:void(0);">SERVICII</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/services/services1">Instalații Termice</a></li>
                                            <li><a routerLink="/services/services2">Instalații sanitare și de stins incendii</a></li>
                                            <li><a routerLink="/services/s-agricultural">Instalații Electrice</a></li>
                                            <li><a routerLink="/services/s-automotive">Instalații de Climatizare</a></li>
                                            <li><a routerLink="/services/s-chemical">Instalații HVAC</a></li>
                                            <li><a routerLink="/services/s-civil">Instalații și sisteme de efracție </a></li>
                                            <li><a routerLink="/services/s-mechanical">Instalații și sisteme de supraveghere video</a></li>
                                            <li><a routerLink="/services/s-oilgas">Instalații și sisteme de detecție incendiu </a></li>
                                            <li><a routerLink="/services/s-power-energy">Mentenanța instalațiilor de semnalizare</a></li>
            
                                        </ul>
                                    </li>
                                    <li><a href="javascript:void(0);">Project</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/projects/project-grid">Project Grid</a></li>                                        
                                            <li><a routerLink="/projects/project-masonry">Project Masonry</a></li>
                                            <li><a routerLink="/projects/project-carousel">Project Carousel</a></li>
                                            <li><a routerLink="/projects/project-detail">Project Detail</a>
                                        </ul>                                
                                   </li>
                                    <li><a href="javascript:void(0);">Shop</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/products/product">Shop</a></li>                                        
                                            <li><a routerLink="/products/product-detail">Shop Detail</a></li>
                                            <li><a routerLink="/products/shopping-cart">Shopping Cart</a></li>
                                            <li><a routerLink="/products/checkout">Checkout</a></li>
                                            <li><a routerLink="/products/wish-list">Wishlist</a></li>                                            
                                        </ul>                                
                                   </li>                                   
                                    <li><a href="javascript:void(0);">Blog</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/blog/blog-grid">Blog Grid</a></li>                                        
                                            <li><a routerLink="/blog/blog-list-sidebar">Blog List</a></li>
                                            <li><a routerLink="/blog/blog-post-right-sidebar">Blog Post</a></li>
                                        </ul>                                
                                    </li>                                                                
                                    <li>
                                        <a href="javascript:void(0);">Pages</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/other/our-prices">Pricing Plan</a></li>
                                            <li><a routerLink="/other/icon-font">Icon Font</a></li>
                                            <li><a routerLink="/other/team">Team</a></li>
                                            <li><a routerLink="/other/team-single">Team Single</a></li>                                            
                                            <li><a routerLink="/other/faq">FAQ</a></li>
                                            <li><a routerLink="/other/error-403">Error 403</a></li>
                                            <li><a routerLink="/other/error-404">Error 404</a></li>
                                            <li><a routerLink="/other/error-405">Error 405</a></li>                                                 
                                        </ul>
                                    </li>
                                    <li><a routerLink="/other/contact1">Contact us</a></li>                                
                                </ul>  
    
                            </div>
                            
                            <!-- Header Right Section-->
                            <div class="extra-nav header-1-nav">
                                <div class="extra-cell one">
                                    <div class="header-search">
                                        <a id="#search-two" class="header-search-icon"><i class="fa fa-search"></i></a>
                                    </div>    
                                </div>
                                <div class="extra-cell two">
                                    <a href="javascript:void(0);" class="wt-cart cart-btn" title="Your Cart">
                                        <span class="link-inner">
                                            <span class="woo-cart-total"> </span>
                                            <span class="woo-cart-count">
                                                <i class="fa fa-shopping-bag"></i>
                                                <span class="shopping-bag wcmenucart-count ">2</span>
                                            </span>
                                        </span>
                                    </a>
                                    
                                    <div class="cart-dropdown-item-wraper clearfix">
                                        <div class="nav-cart-content">
                                            
                                            <div class="nav-cart-items p-a15">
                                                <div class="nav-cart-item clearfix">
                                                    <div class="nav-cart-item-image">
                                                        <a href="javascript:void(0);"><img src="assets/images/home-11/cart/pic-1.jpg" alt="p-1"></a>
                                                    </div>
                                                    <div class="nav-cart-item-desc">
                                                        <span class="nav-cart-item-price"><strong>2</strong> x $19.99</span>
                                                        <a href="javascript:void(0);">Heavy helmet</a>
                                                        <a href="javascript:void(0);" class="nav-cart-item-quantity radius-sm">x</a>
                                                    </div>
                                                </div>
                                                <div class="nav-cart-item clearfix">
                                                    <div class="nav-cart-item-image">
                                                        <a href="javascript:void(0);"><img src="assets/images/home-11/cart/pic-1.jpg" alt="p-2"></a>
                                                    </div>
                                                    <div class="nav-cart-item-desc">
                                                        <span class="nav-cart-item-price"><strong>1</strong> x $24.99</span>
                                                        <a href="javascript:void(0);">Power drill</a>
                                                        <a href="javascript:void(0);" class="nav-cart-item-quantity radius-sm">x</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="nav-cart-title p-tb10 p-lr15 d-flex">
                                                <h4  class="m-a0">Subtotal:</h4>
                                                <h5 class="m-a0">$67.97</h5>
                                            </div>
                                            <div class="nav-cart-action p-a15 d-flex justify-content-start">
                                                <button class="site-button" type="button">View Cart</button>
                                                <button class="site-button-secondry" type="button">Checkout </button>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>                                
                            </div> 

                        </div>
                        
                    </div>   
                </div>


                <!-- SITE Search -->
                <div id="search-two"> 
                    <span class="close"></span>
                    <form role="search-two" id="searchform" action="/search" method="get" class="radius-xl">
                        <div class="input-group">
                            <input class="form-control" value="" name="q" type="search" placeholder="Type to search"/>
                            <span class="input-group-append"><button type="button" class="search-btn"><i class="fa fa-paper-plane"></i></button></span>
                        </div> 
                    </form>
                </div> 
        
            </div>
        </div>
    </div>                
    
</header>