<div class="section-full p-t80 p-b40 {{bgplain==1 ? 'bg-gray' : 'overlay-wraper bg-cover bg-no-repeat bg-parallax'}}" data-stellar-background-ratio="0.5"  style="background-image:url({{data.bgImage}})">
    <div *ngIf="bgplain!=1" class="overlay-main site-bg-secondry {{data.opacity}}"></div>                                       

            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center {{bgplain==1 ? '' : 'text-white'}}">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END--> 

                <div class="counter3-section-outer-top">
                    <div class="counter-outer">                            
                        
                        <div class="row justify-content-center">
                                
                            <div class="col-lg-3 col-md-6 m-b30" *ngFor="let object of data.figures">
                                <div class="wt-icon-box-wraper center">
                                    <h2 class="counter site-text-primary">{{object.count}}</h2>
                                    <span class="{{bgplain==1 ? '' : 'site-text-white'}} title-style-2">{{object.title}}</span>
                                </div>
                            </div>                                       

                        </div>                            
                    
                    </div>   
                </div>  

                                                                            
           </div>

        </div>