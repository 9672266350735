<div id="graphic-design-19" class="tab-pane">
    <table class="table m-b0 no-border">
        <tr>
            <td><b>Size</b></td>
            <td>Small, Medium, Large & Extra Large</td>
        </tr>
        <tr>
            <td><b>Color</b></td>
            <td>Read, Blue, Green & Black</td>
        </tr>
        <tr>
            <td><b>Length</b></td>
            <td>35 cm</td>
        </tr>
        <tr>
            <td><b>Fabric</b></td>
            <td>Cotton, Silk & Synthetic</td>
        </tr>
        <tr>
            <td><b>Warranty</b></td>
            <td>6 Months</td>
        </tr>
    </table>
</div>