<div class="section-full p-t0 p-b50 bg-white get-in-touch-wraper">
            
    <div class="container">
        <div class="get-in-touch">
            
            <div class="row justify-content-between d-flex align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="get-in-touch-pic">
                        <img src="assets/images/home-11/intro-pic4.png" alt="">
                        <!-- Circle Animation Start-->
                            <div class='ripple-background'>
                                <div class='ls-circle ls-xxlarge ls-shade1'></div>
                                <div class='ls-circle ls-xlarge ls-shade2'></div>
                                <div class='ls-circle ls-large ls-shade3'></div>
                                <div class='ls-circle ls-medium ls-shade4'></div>
                                <div class='ls-circle ls-small ls-shade5'></div>
                            </div>
                        <!-- Circle Animation End-->   
                    </div>  
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="get-in-touch-info">

                        <h2 class="wt-title">Quality and reliability services to 
                            provide a solution to your project,
                            we do it all and fast
                        </h2>
                        
                        <a routerLink="/about/about1" class="site-button site-btn-effect">Download PDF</a>
                        
                    
                    </div>
                </div>
    

            </div>

        </div>
    </div>

</div>