<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <!-- <app-section-inner-banner [data]="banner"></app-section-inner-banner> -->
    <!-- INNER PAGE BANNER END -->

    <!-- SECTION CONTENTG START -->

    <!-- CONTACT FORM -->
    <div
      class="section-full p-t60 p-b50 bg-cover"
      style="background-image: url(assets/images/bg-7.jpeg)"
    >
      <div class="section-content">
        <div class="container">
          <div class="contact-one">
            <!-- CONTACT FORM-->
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-8 col-md-10 m-b20">
                <div class="contact-info">
                  <div class="contact-info-inner">
                    <!-- TITLE START-->
                    <div class="text-center m-b30">
                      <h2>Informații de contact</h2>
                    </div>
                    <!-- TITLE END-->

                    <div
                      class="contact-info-section"
                      style="
                        background-image: url(assets/images/background/bg-map2.png);
                      "
                    >
                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">
                            Numere de telefon
                          </h3>
                          <p>0723 000 585</p>
                          <p>0740 010 252</p>
                          <p>0745 777 585</p>
                        </div>
                      </div>

                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">
                            Adresă de email
                          </h3>
                          <p>officesklsolution@gmail.com</p>
                          <p>office@skl.ro</p>
                        </div>
                      </div>

                      <div class="wt-icon-box-wraper left m-b30">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Adresă</h3>
                          <p>Cluj-Napoca, Borhanciului 13</p>
                        </div>
                      </div>

                      <div class="wt-icon-box-wraper left">
                        <div class="icon-content">
                          <h3 class="m-t0 site-text-primary">Program</h3>
                          <ul class="list-unstyled m-b0">
                            <li>Luni-Vineri: 8:00 – 18:00</li>
                            <li>Sâmbătă: Închis</li>
                            <li>Duminică: Închis</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GOOGLE MAP -->
    <div class="section-full bg-white p-tb80">
      <div class="section-content">
        <div class="container">
          <!-- <div class="gmap-outline"> -->
          <!-- <google-map height="500px" width="100%" -->
          <!-- [center]="{lat: 46.753351, lng: 23.5785768}"></google-map> -->
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="400px"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=SKL%20tECHNION%20sOLUTION&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe><br /><style>
                .mapouter {
                  position: relative;
                  text-align: right;
                  height: 100%;
                  width: 100%;
                }</style><style>
                .gmap_canvas {
                  overflow: hidden;
                  background: none !important;
                  height: 500px;
                  width: 100%;
                }
              </style>
            </div>
          </div>

          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>
