import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-footer3',
  templateUrl: './section-footer3.component.html',
  styleUrls: ['./section-footer3.component.css']
})
export class SectionFooter3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
