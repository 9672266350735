<div class="section-full p-t80 p-b50 bg-gray">
    <div class="container">
        <div class="section-content">

            <div class="section-content">
                <div class="pricingtable-row p-lr15 no-col-gap">
                    <div class="row d-flex justify-content-center">
                    

                        <div class="col-lg-4 col-md-6 col-sm-12 m-b30" *ngFor="let object of data; let i = index;">
                            <div class="pricingtable-wrapper {{i%2==0?'bg-white':''}} pt-with-icon">

                                <div class="pricingtable-inner {{i%2==0?'':'pricingtable-highlight'}}">
                                    <div class="pt-pic-outer"><div class="pt-pic"><img src="{{object.image}}" alt=""></div></div>                                            
                                    <div class="pricing-table-top-section">
                                        <div class="pricingtable-price">
                                            <h2 class="pricingtable-bx"><sup class="pricingtable-sign">$</sup>{{object.price}}</h2>
                                        </div>
                                        
                                        <div class="pricingtable-title">
                                            <h2 class="title-style-2">{{object.plan}} Plan</h2>
                                        </div>                                                    
                                    </div>
                                    
                                    <ul class="pricingtable-features">
                                        <li *ngFor="let obj of object.includes.split(',')">{{obj}}</li>
                                    </ul>
                                    
                                    <div class="pricingtable-footer">
                                        <a routerLink="/other/our-prices" class="{{i%2==0?'site-button':'site-button-secondry'}} site-btn-effect">Select</a>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>    
            
        </div>
    </div>
</div>