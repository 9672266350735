<div class="section-full p-t80 overlay-wraper services-main-section bg-white">
                                   
    <div class="section-content services-section-content">
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-section-content-left">
                    <!-- TITLE START-->
                    <div class="left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                        <p>
                            {{data.description}}
                        </p>
                        <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a>
                    </div>
                    <!-- TITLE END-->
                </div>                        
            </div> 
        
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-section-content-right2">
                    <div class="owl-carousel services-slider owl-btn-vertical-center">
                    
                        <div class="item" *ngFor="let object of data.services">
                            <div class="wt-box bg-gray  p-a10 project-2-block">
                                <div class="wt-thum-bx">
                                    <img src="{{object.image}}" alt="">
                                </div>
                                <div class="wt-info">
                                    <h4 class="wt-title m-b0 m-t15"><a routerLink="/services/s-oilgas">{{object.title}}</a></h4>
                                </div>
                            </div>
                        </div>                                   
                
                    </div>
                </div>
            </div>
        </div>
    </div>                                        

</div>