<div class="wt-bnr-inr overlay-wraper bg-center" style="background: var(--background-gradient)">
    <div class="overlay-main"></div>
    <div class="container">
        <div class="wt-bnr-inr-entry">
            <div class="banner-title-outer">
                <div class="banner-title-name">
                    <h2 class="text-gray">{{data.title}}</h2>
                </div>
            </div>
            <!-- BREADCRUMB ROW -->

            <div *ngIf="data.crumb">
                <ul class=" wt-breadcrumb breadcrumb-style-2">
                    <li><a routerLink="/index">Carieră</a></li>
                    <li class="site-text-primary">{{data.crumb}}</li>
                </ul>
            </div>

            <!-- BREADCRUMB ROW END -->
        </div>
    </div>
</div>