<div class="section-full welcome-section-outer">
    <div class="welcome-section-top bg-white p-tb80">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-md-12">
                    <div class="img-colarge">
                        <div class="colarge-1"><img src="{{data.image}}" alt="" class="slide-righ"></div>
                        <div class="since-year-outer">
                            <div class="since-year"><span>Din</span><strong>{{data.since}}</strong></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="welcom-to-section">
                        <h2 class="site-text-primary">{{data.subtitle}}</h2>

                        <p [innerHtml]="data.description"></p>

                        <div class="welcom-to-section-bottom d-flex justify-content-between">
                            <div class="welcom-btn-position"><a routerLink="/services/services1"
                                    class="site-button-secondry site-btn-effect">Află mai mult</a></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>