<div class="section-full  p-t80 p-b50 bg-white  bg-bottom-right bg-no-repeat" style="background-image:url(assets/images/background/bg-5.png)">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-6 col-md-12 m-b30">
                <!-- TITLE START-->
                <div class="left wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                    <p>{{data.description}}</p>
                </div>
                <!-- TITLE END-->
                <div class="video-3-section-outer-bottom">
                    <div class="video-section2-outer mfp-gallery">
                        <div class="video-section">
                             <a href="{{data.videoLink}}" class="mfp-video play-now">
                                <i class="icon fa fa-play"></i>
                                <span class="ripple"></span>
                            </a>                                              
                        </div>	    
                    </div>      
                
                    <div class="video3-section-blockquote">
                        <i class="fa fa-quote-left"></i>
                        <span class="q-author-detail site-text-secondry title-style-2">{{data.quote}}</span>
                        <div class="q-author-name site-text-primary title-style-2">{{data.quoteBy}}</div>
                    </div>
                </div>                                                                                  
            </div>
            <div class="col-lg-6 col-md-12 m-b30">
            
                 <div class="home-contact-section site-bg-primary p-a40">
                    <form class="cons-contact-form" method="post" action="../../../../handlers/form-handler2.php">
                       
                        <!-- TITLE START-->
                        <div class="wt-small-separator-outer text-white">
                            <h2>Feel free to get in touch!</h2>
                        </div>
                        <!-- TITLE END-->
                                                                
                        <div class="row">
                           <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="username" type="text" required class="form-control" placeholder="Name">
                                </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                   <input name="email" type="text" class="form-control" required placeholder="Email">
                                </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="phone" type="text" class="form-control" required placeholder="Phone">
                                 </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                     <input name="subject" type="text" class="form-control" required placeholder="Subject">
                                 </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="form-group">
                                   <textarea name="message" class="form-control" rows="4" placeholder="Message"></textarea>
                                 </div>
                            </div>
                            
                           <div class="col-md-12">
                                <button type="submit" class="site-button-secondry site-btn-effect">Send us a message</button>
                            </div>
                            
                        </div>
                   </form>                                        
                </div>
                                    
            </div>
       </div>
    </div>
    
</div>