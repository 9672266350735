import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-s-civil',
  templateUrl: './page-s-civil.component.html',
  styleUrls: ['./page-s-civil.component.css']
})
export class PageSCivilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
