<div class="section-full p-t80 p-b50 bg-white">
    <div class="container">
        <div class="section-content">

                
                <div class="section-content">
                    <div class="row home12-contact-section-wrap">

                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="contact-right12-section">
                                <div class="wt-media">
                                    <img src="assets/images/home-11/contact-img.jpg" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="home12-contact-section m-b30">
                                <form class="cons-contact-form" method="post" action="../../../../handlers/form-handler2.php">
                                
                                    <!-- TITLE START-->
                                    <div class="section-head left wt-small-separator-outer">
                                        <div class="wt-small-separator site-text-primary">
                                            <div>Have Questions?</div>
                                        </div>
                                        <h2>Feel free to get in touch! contact now</h2>
                                    </div>
                                    <!-- TITLE END-->

                                    
                                                                            
                                    <div class="row m-b30">
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <input name="username" type="text" required class="form-control" placeholder="Name">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                            <input name="email" type="text" class="form-control" required placeholder="Email">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <input name="phone" type="text" class="form-control" required placeholder="Phone">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <input name="subject" type="text" class="form-control" required placeholder="Subject">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                            <textarea name="message" class="form-control" rows="4" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <button type="submit" class="site-button site-btn-effect">Send us a message</button>
                                        </div>
                                        
                                    </div>
                                </form>                                        
                            </div>
                        </div>
                        
                    </div>
                </div>      
            
        </div>
    </div>
</div>