import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-blogs9',
  templateUrl: './section-blogs9.component.html',
  styleUrls: ['./section-blogs9.component.css']
})
export class SectionBlogs9Component implements OnInit {

  @Input() data: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
