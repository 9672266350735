import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sisteme-securitate',
  templateUrl: './sisteme-securitate.component.html',
  styleUrls: ['./sisteme-securitate.component.css']
})
export class SistemeSecuritateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    title: "SISTEME DE SECURITATE",
  }
}
