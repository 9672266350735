<div class="section-full p-t80 p-b50 bg-white team-bg-section-outer">
    <div class="container">
        <div class="section-content">
        
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->
                   
        <div class="section-content">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 m-b30" *ngFor="let object of data.members">
                    <div class="wt-team-10">
                        
                        <div class="wt-media">
                            <img src="{{object.image}}" alt="">
                        </div>                                   

                        <div class="wt-info">
                            <div class="team-detail p-t30">
                                <span class="title-style-2 team-position site-text-primary">{{object.speciality}}</span>                                        
                                <h3 class="m-t0 team-name"><a routerLink="/other/team-single" class="site-text-secondry">{{object.name}}</a></h3>
                            </div>
                            <div class="team-social-center">
                                <ul class="team-social-bar">
                                    <li><a href="https://www.facebook.com/"><i class="fa fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
                                    <li><a href="https://www.tumblr.com/"><i class="fa fa-tumblr"></i></a></li>
                                </ul>
                            </div>                                        
                        </div>
                
                    </div>
                </div>                                                       
                
            </div>
        </div>      
            
        </div>
    </div>
</div>