import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-about2',
  templateUrl: './section-about2.component.html',
  styleUrls: ['./section-about2.component.css']
})
export class SectionAbout2Component implements OnInit {

  data = {
    title: "VIZIUNEA ȘI VALORILE NOASTRE",
    image1: "assets/images/instalatii_civile2.jpeg",
    image2: "assets/images/Electrical-engeneers.jpeg",
    since: "2011",
    sign: "assets/images/sign.png",
    description: "Viziunea și valorile <span class='site-text-primary'>SKL Technion Solution</span> se bazează atât pe competențele tehnice, cât și pe muncă în echipă, seriozitate, integritate și implicare. Credem că rezultatul muncii noastre contribuie la îmbunătățirea societății: infrastructura construită și serviciile oferite stau la baza dezvoltării viitoare. Dinamismul și performanța  <span class='site-text-primary'>SKL Technion Solution</span> se cladește în fiecare zi pe integritatea, energia și motivația echipei, a cărei competență ne diferențiază de concurenți. Proiectele în care suntem implicați contribuie la creșterea nivelului de expertiză și învățare continuă.",
    services: [
      {
        value: "Profesionalism."
      },
      {
        value: "Calitate."
      },
      {
        value: "Integritate."
      },
      {
        value: "Incredere. Oferim și câstigăm încredere."
      },
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

}
