<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->
    <div class="section-full p-t40 p-b40 bg-white">
      <div class="container">
        <p class="text-justify indent-paragraph">
          Compania
          <span class="site-text-primary">SKL Technion Solution</span> este o
          companie de inginerie specializată pe servicii de execuție și
          implementare de soluții integrate și complete în domeniul instalaților
          de curenți tari și slabi din sectoarele civile, industriale și
          energetice.
          <span class="site-text-primary">SKL Technion Solution</span> este o
          companie parteneră a unei companii de talie globală, Lummii Group
          LTD, specializată în identificarea și implementarea soluțiilor de
          optimizare a consumurilor energetice, și implicit a costurilor
          operaționale. Compania parteneră vine cu un concept și soluții
          inovative în domeniul eficienței energetice, precum și cu tehnologia
          și echipamentele adecvate fiecarei soluții în parte.
        </p>

        <div class="whatWedo-info-section">

          <!-- TITLE END-->
          <div class="wt-icon-card-outer despre-noi">
            <div class="wt-icon-card bg-white shadow" *ngFor="let object of data.work">
              <div class="wt-card-header">
                <div class="wt-icon-box-sm site-bg-primary m-r10">
                  <span class="icon-cell service-icon about-us-service-icon">
                    <img [src]="object.icon" />
                  </span>
                </div>
                <span class="title-style-2 site-text-primary">{{
                  object.title
                  }}</span>
              </div>
              <div class="wt-card-content">
                <p>{{ object.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="wt-accordion acc-bg-gray m-t40 about-section-three-acc text-justify" id="accordion">
          <div class="panel wt-panel" *ngFor="let object of data.specifications; let i = index">
            <div class="accord-head {{ i == 0 ? 'acc-actives' : '' }}" id="heading{{ i }}">
              <h5 class="{{ i == 0 ? 'acod-title' : 'mb-0' }}">
                <a class="{{ 'collapsed' }}" data-toggle="collapse" href="#collapse{{ i }}"
                  aria-expanded="{{ 'false' }}" aria-controls="collapse{{ i }}">
                  {{ object.title }}
                  <span class="indicator"><i class="fa"></i></span>
                </a>
              </h5>
            </div>

            <div id="collapse{{ i }}" class="collapse" aria-labelledby="heading{{ i }}" data-parent="#accordion">
              <div class="acod-content p-tb15 p-lr30">
                <ul>
                  <li *ngFor="let spec of object.list" [innerHtml]="spec"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FOOTER START -->
  <app-section-footer1></app-section-footer1>
  <!-- FOOTER END -->
</div>

<app-loader></app-loader>