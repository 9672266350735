import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {
  ProjectsService as ProjectsService,
  ProjectDescription,
} from '../../services/projects.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { GalleryModalComponent } from 'src/app/shared/gallery-modal/gallery-modal.component';

@Component({
  selector: 'app-page-project-detail',
  templateUrl: './page-project-detail.component.html',
  styleUrls: ['./page-project-detail.component.css'],
})
export class PageProjectDetailComponent implements OnInit {
  selectedProject: ProjectDescription;

  banner = {
    background: 'assets/images/banner/8.jpg',
    title: '',
    // crumb: "Project Detail"
  };

  images = [
    'assets/images/gallery/pic1.jpg',
    'assets/images/gallery/pic2.jpg',
    'assets/images/gallery/pic3.jpg',
    'assets/images/gallery/pic4.jpg',
  ];

  details = {
    project: 'Refinery Industrial',
    category: 'Business, Industrial',
    time: '2 Month',
    status: 'Good',
    client: 'Matthew Stone',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService,
    private simpleModalService: SimpleModalService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.setProject();
      });
  }

  ngOnInit(): void {
    this.setProject();
  }

  onGallery(): void {
    this.simpleModalService
      .addModal(GalleryModalComponent, {
        photosRoute: this.selectedProject.gallery,
      })
      .subscribe((modalResponse) => {});
  }

  setProject() {
    const index = Number(this.route.snapshot.paramMap.get('index'));
    let project = this.projectsService.all[index];

    if (project.isActive) {
      this.selectedProject = project;
      this.banner.title = this.selectedProject.title;
    } else {
      this.router.navigate(['/other/error-404']);
    }
  }
}
