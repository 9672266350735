<div class="section-full  p-t80 p-b50 bg-white">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-9 col-md-12 m-b30">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                    <p>{{data.description}}</p>
                </div>
                <!-- TITLE END-->
                <div class="video-3-section-outer-bottom">
                    <div class="video-section2-outer mfp-gallery">
                        <div class="video-section">
                             <a href="{{data.videoLink}}" class="mfp-video play-now">
                                <i class="icon fa fa-play"></i>
                                <span class="ripple"></span>
                            </a>                                              
                        </div>	    
                    </div>      
                
                    <div class="video3-section-blockquote">
                        <i class="fa fa-quote-left"></i>
                        <span class="q-author-detail site-text-secondry title-style-2">{{data.quote}}</span>
                        <div class="q-author-name site-text-primary title-style-2">{{data.quoteBy}}</div>
                    </div>
                </div>                                                                                  
            </div>
            
       </div>
    </div>
    
</div>