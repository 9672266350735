<div class="section-full  service-gallery-style1-wrapper">
            
    <div class="services-gallery-block-outer">
        <div class="container half-wide-bg-outer p-t90 p-b50 ">
            <div class="half-wide-bg"></div>
            <div class="section-content"> 
                <div class="row">
        
                    <div class="col-lg-4 col-md-12 m-b30">
                        
                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer text-white">
                            <div class="wt-small-separator site-text-white">
                                <div>{{data.title}}</div>
                            </div>
                            <h2 class="wt-title">{{data.subtitle}}</h2>
                            <p>{{data.description}}</p>

                            <a routerLink="/about/about1" class="site-button-secondry site-btn-effect">Explore all services</a>
                            
                        </div>
                        <!-- TITLE END-->
                        
                        
                                                    
                    </div>
    
                    <div class="col-lg-8 col-md-12">
                        <div class="services-gallery-style2">
                            <div class="owl-carousel services-gallery-two owl-btn-vertical-center ">
                            
                                <!-- COLUMNS 1 --> 
                                <div class="item" *ngFor="let object of data.services">
                                    <div class="service-box-style2">
                                        <div class="service-media">
                                            <img src="{{object.image}}" alt="">
                                        </div>
                                        <div class="service-content">
                                            <h3 class="service-title-large"><a routerLink="/services/service-detail">{{object.title}}</a></h3>
                                            <p>{{object.description}}</p>
                                            <a routerLink="/services/service-detail" class="site-button site-btn-effect">View All</a>
                                        </div>
                                    </div>
                                </div>
                                            
                        
                            </div>
                        </div>

                    </div>  

                </div>
            </div>                              
        </div>
    </div>
</div>