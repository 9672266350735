import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { JobsService } from 'src/app/pages/services/jobs.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {


  selectedJob;
  currentIndex;
  isCriteriiSelectieArray =  false;

  banner = {
    background: "assets/images/banner/8.jpg",
    title: "CARIERĂ",
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jobsService: JobsService,
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((e) => {
      this.setJob();
    });
  }

  ngOnInit(): void {
    this.setJob();
  }

  setJob() {
    const index = Number(this.route.snapshot.paramMap.get('index'))
    if (this.currentIndex !== index) {
      this.currentIndex = index;
      let job = this.jobsService.all[index];
      if (job.isActive) {
        this.selectedJob = job;
        this.isCriteriiSelectieArray = Array.isArray(this.selectedJob.criterii_selectie);
      } else {
        this.router.navigate(['/other/error-404'])
      }
    }
  }
}
