<div class="section-full bg-cover p-t80 p-b50" style="background-image:url(assets/images/home-11/background/bg11.jpg);">
            
    <div class="container">

            <div class="testimonial11-outer m-b30 bg-white">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div>{{data.title}} </div>
                    </div>
                    <h2 class="wt-title">{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END-->

                <div class="section-content">
                    <div class="testimonial-11-content owl-carousel owl-btn-bottom-center">
                        <!--block 1-->    
                        <div class="item" *ngFor="let object of data.quotes">
                            <div class="testimonial-11">
                                <div class="testimonial-content">
                                    <div class="testimonial-detail clearfix">
                                        <div class="testimonial-pic-block"> 
                                            <div class="testimonial-pic">
                                                <img src="{{object.image}}" alt="">
                                            </div>
                                        </div>                                          
                                        <div class="testimonial-info">
                                            <span class="testimonial-name">{{object.name}} </span>
                                            <span class="testimonial-position">{{object.designation}}</span>
                                        </div>
                                    </div>
                                    
                                    <div class="testimonial-text">
                                        <p>
                                            {{object.quote}}
                                        </p>
                                        <i class="fa fa-quote-right"></i>                                                
                                    </div> 

                                </div>
                            </div>
                        </div>
                
                </div>
                </div>
            </div>

    </div>    
</div>