<div class="page-wraper">
  <!-- HEADER START -->
  <app-section-header1></app-section-header1>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner [data]="banner"></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- FAQ SECTION START -->

    <div class="section-full p-t20 p-b40 bg-white">
      <div class="container">
        <div class="section-content">
          <div class="container text-justify">
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30">
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Audit și Eficiență Energetică
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          Pentru o eficiență energetică cât mai ridicată un prim
                          pas important îl reprezintă efectuarea unui audit 
                          energetic industrial.
                        </li>
                        <li>
                          Pentru mediul industrial din România, legea 121/2014 privind 
                          eficiența energetică (modificată prin Legea 160/2016) îi 
                          obligă pe operatorii economici care consumă anual o cantitate 
                          de energie de peste 1.000 tone echivalent petrol să efectueze, 
                          o dată la 4 ani, un audit energetic pe 51% din totalul 
                          consumului energetic.
                        </li>
                        <li>
                          La acesta se adaugă întocmirea unor programe de îmbunătățire a 
                          eficienței energetice care includ măsuri pe termen scurt, mediu 
                          și lung.
                        </li>
                        <li>
                          Cum se desfășoară un audit energetic industrial de care 
                          partenerul nostru LER Lummii Energy Romania specializat 
                          în domeniul solutiilor de eficientă?
                        </li>
                        <li>
                          1. Împreună cu beneficiarul, specialiștii acreditați în 
                             realizarea unui audit energetic industrial stabilesc 
                             conturul de bilanț energetic analizat.
                        </li>
                        <li>
                          2. Sunt identificate principalele fluxuri energetice folosite 
                             și sunt inspectate instalațiile supuse auditării, în vederea 
                             stabilirii gradului de uzură al echipamentelor.
                        </li>
                        <li>
                          3. Urmează culegerea de date specifice fiecărei instalații și 
                             sunt stabilite schemele și punctele unde vor fi montate 
                             instrumentele de măsură.
                        </li>
                        <li>
                          4. Toți acești pași permit întocmirea diagramelor de consum și 
                             pierderi,identificarea zonelor cu pierderi și stabilirea 
                             utilajelor și echipamentelor neperformante energetic și tehnologic.
                        </li>
                        <li>
                          5. Pe baza datelor strânse, specialiștii în audit energetic industrial
                             evidențiază principalele oportunități de eficientizare energetică. 
                             Astfel, pot fi luate măsuri de intervenție pentru scăderea consumului 
                             energetic în produsul final.
                        </li>
                        <li>
                          6. În planul de măsuri și acțiuni pe care îl întocmesc sunt cuprinse și 
                             costurile de implementare ale soluțiilor de eficiență energetică 
                             preconizate și perioada de amortizare a investiției propuse.
                        </li>
                        <li>
                          Cine este LER Lummii Energy Romania, care este modul de lucru, ce oferă
                          și care este conceptul nou pe care îl aduce?.....
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pdf-reader-wrapper">
              <div class="pdf-reader-title">
                <span>Lummii Energy Presentation & SMARRT Process</span>
              </div>
              <pdf-viewer src="../../../../../assets/pdfs/Lummii Energy Presentation & SMARRT Process v2.0.pdf"
                [render-text]="true" [original-size]="false" class="pdf-reader"></pdf-viewer>
            </div>
            <div class="pdf-reader-wrapper">
              <div class="pdf-reader-title">
                <span>Smart Buildings - Lummii Mini BMS</span>
              </div>
              <pdf-viewer src="../../../../../assets/pdfs/Smart Buildings -  Lummii Mini BMS (1).pdf"
                [render-text]="true" [original-size]="false" class="pdf-reader"></pdf-viewer>
            </div>

            <h3>Sisteme BMS si tehnologie SMART</h3>
            <p>
              Clădirile moderne au în dotare instalații și sisteme care monitorizează și automatizează diferite
              funcționaliăți precum: alimentarea cu energie electrică, încalzirea, ventilația și aerul condiționat
              (HVAC), protecția la incendiu, securitatea, accesul, iluminatul.
            </p>
            <p>
              Consumul mare de energie, aproximativ atribuit, 40% climatului interior ( HVAC ) și 30% iluminatului și
              complexitatea operării sistemelor, au impus necesitatea integrării acestora prin intermediul BMS-urilor (
              Building Management System ).
            </p>
            <div class="row d-flex justify-content-center flex-wrap">
              <div class="col-lg-12 col-md-12 m-b30">
                <div class="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Un sistem BMS poate integra urmatoarele subsisteme:
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>Instalații HVAC;</li>
                        <li>Contorizări energie (electrică, termică);</li>
                        <li>
                          Tablouri generale de distribuție și cele ale consumatorilor;
                        </li>
                        <li>Grupuri electrogene si UPS-uri;</li>
                        <li>Instalații apă si solare;</li>
                        <li>Sisteme de ascensoare;</li>
                        <li>Iluminat;</li>
                        <li>Sisteme de siguranță și securitate;</li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel wt-panel">
                    <div class="accord-head acc-actives">
                      <h3 class="acod-title">
                        <a data-toggle="collapse" data-parent="#accordion5">
                          Beneficii ale BMS-urilor:
                          <span class="indicator"><i class="fa fa-star"></i></span>
                        </a>
                      </h3>
                    </div>
                    <div class="acod-body show">
                      <ul class="acod-content p-l40 p-r40 p-t5 p-b20 bg-gray">
                        <li>
                          reduce costurile de operare ale clădirilor, prin optimizare energetică, datorită controlului
                          optim al instalațiilor și sistemelor pe care le integrează.
                        </li>
                        <li>
                          reduce costurile de operare ale cladirilor, prin minimalizarea operațiunilor de mentenantă și
                          servicii;
                        </li>
                        <li>
                          consolidază valorea investiției pentru dezvoltator sau proprietar;
                        </li>
                        <li>
                          crește semnificativ gradul de confort in cladire;
                        </li>
                        <li>crește gradul de siguranța în clădire;</li>
                        <li>crește gradul de securitate în clădire;</li>
                        <li>
                          extinde durata de viață a echipamentelor și sistemelor;
                        </li>
                        <li>
                          crește productivitatea în rândul personalului ce deservește clădirea;
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>
              Soluțiile și tehnologiile integrate BMS au la baza tehnologii, software, sisteme si echipamente produse de
              compani precum Schneider Electric.
            </p>
            <p>
              Sistemele BMS SE utilizează cele mai noi tehnologi IoT (Internet of Things) și AI (Inteligență
              artificială) ceea ce le crează un grad sporit de integrabilitate, o capacitate extinsă de culegere de date
              și de învățare să opereze autonomi, să rezolve probleme ce pot apărea în funcționarea clădirilor.
            </p>
            <p>
              Sistemele BMS SE sunt modulare, și permit integrarea rapidă cu restul sistemelor indiferent de cine este
              producatorul acestora, cu obțiunea de a fii extins ușor.
            </p>
            <p>
              Gama de echipamente comercializate cuprinde, controlere, senzori,
              termostate, servomotoare, vane, convertizoare de frecventa,
              contoare, sisteme de iluminat inteligent.
            </p>
          </div>
        </div>
        <!-- FAQ SECTION END -->
      </div>
      <!-- CONTENT END -->

      <!-- FOOTER START -->
      <app-section-footer1></app-section-footer1>
      <!-- FOOTER END -->
    </div>

    <app-loader></app-loader>
  </div>
</div>