<div class="section-full {{data.bgcolor}}">
    <div class="container">
        <div class="section-content">

            <!-- TESTIMONIAL 4 START ON BACKGROUND -->
            <div class="section-content">
                <div class="section-content p-tb30">
                    <div class="owl-carousel logos-slider owl-btn-vertical-center">
                        <div class="item" *ngFor="let object of data.clients">
                            <div class="ow-client-logo">
                                <div class="client-logo client-logo-media">
                                    <img src="{{object}}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>