import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DespreNoiComponent } from './pages/others/despre-noi/despre-noi.component';
import { CarieraComponent } from './pages/others/page-cariera/cariera.component';
import { JobDetailsComponent } from './pages/others/page-cariera/job-details/job-details.component';
import { PageContactUsComponent } from './pages/others/page-contact-us/page-contact-us.component';

const routes: Routes = [
  {
    path: 'acasa',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  { path: 'servicii', loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule) },
  { path: 'proiecte', loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'despre-noi', component: DespreNoiComponent },
  { path: 'cariera', component: CarieraComponent },
  { path: 'cariera/:index', component: JobDetailsComponent },
  { path: 'contact', component: PageContactUsComponent },
  { path: '', redirectTo: 'acasa', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
