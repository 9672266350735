<div class="section-full  p-t80 p-b50 testimonial-4-outer bg-gray-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 m-b30 d-flex align-items-center" >
                <div class="testimonial-4-left-section">
                    <!-- TITLE START-->
                    <div class="left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                            <div  class="sep-leaf-left"></div>
                            <div>{{data.title}}</div>
                            <div  class="sep-leaf-right"></div>
                        </div>
                        <h2>{{data.subtitle}}</h2>
                        <p>{{data.description}}</p>
                        <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a>
                    </div>
                    <!-- TITLE END--> 
                </div>                       
            </div>
            <div class="col-lg-6 col-md-12 m-b30">
                <div class="testimonial-4-content-outer">
                    <div class="testimonial-4-content owl-carousel  owl-btn-vertical-center long-arrow-next-prev">
                        <div class="item" *ngFor="let object of data.quotes">
                            <div class="testimonial-4 bg-white">
                                <div class="testimonial-content">
                                    <div class="testimonial-pic-block"> 
                                        <div class="testimonial-pic">
                                            <img src="{{object.image}}" alt="">
                                        </div>
                                    </div>                                            
                                    <div class="testimonial-text">
                                        <i class="fa fa-quote-left"></i>
                                        <p>{{object.quote}}</p>
                                    </div>
                                    <div class="testimonial-detail clearfix">
                                        <div class="testimonial-info">
                                            <span class="testimonial-name  title-style-2 site-text-secondry">{{object.quoteBy}}</span>
                                            <span class="testimonial-position title-style-2 site-text-primary">{{object.designation}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                                                       
                    </div>
                </div>                     
            </div>
       </div>
    </div>
</div>