import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-video6',
  templateUrl: './section-video6.component.html',
  styleUrls: ['./section-video6.component.css']
})
export class SectionVideo6Component implements OnInit {

  @Input() data:any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
