<div class="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover services-main-section"
    style="background: var(--background-gradient)">
    <div class=" overlay-main"></div>

    <div class="section-content services-section-content">
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="services-section-content-left">
                    <!-- TITLE START-->
                    <div class="left wt-small-separator-outer text-white">
                        <h1>{{data.subtitle}}</h1>
                        <p>
                            {{data.description}}
                        </p>
                        <a routerLink="/contact" class="site-button-secondry site-btn-effect">CONTACTEAZĂ-NE</a>
                    </div>
                    <!-- TITLE END-->
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="owl-carousel services-slider owl-btn-vertical-center">
                    <div class="item" *ngFor="let service of data.services">
                        <div class="wt-box service-box-1 bg-white">
                            <div class="service-box-title title-style-2 site-text-primary">
                                <span class="s-title-one">{{service.title}}</span>
                            </div>
                            <div class="service-box-content">
                                <p>{{service.description}}</p>
                            </div>
                            <ul>
                                <li class="m-l30" *ngFor="let item of service.list">{{item}}</li>
                            </ul>
                            <div class="wt-icon-box-wraper">
                                <div class="wt-icon-box-md site-bg-primary">
                                    <span class="icon-cell service-icon-home"><img src="{{service.icon}}" alt=""></span>
                                </div>
                                <div class="wt-icon-number"><span>{{ i+1 | number : '2.0'}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
