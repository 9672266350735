<div class="section-full p-t80 p-b40  bg-white bdr-t-1 bdr-solid bdr-gray">

    <div class="container">
        
        <div class="counter2-section-outer-bottom"> 
            <div class="counter-outer">                            
                
                <div class="row justify-content-center">
                        
                    <div class="col-lg-3 col-md-6 col-sm-6 m-b30" *ngFor="let object of data">
                        <div class="wt-icon-box-wraper center">
                            <h2 class="counter site-text-primary">{{object.count}}</h2>
                            <span class="site-text-secondry title-style-2">{{object.title}}</span>
                        </div>
                    </div>                                      

                </div>                            
            
            </div>   
        </div>  
                                                                    
   </div>
</div>