import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header2',
  templateUrl: './section-header2.component.html',
  styleUrls: ['./section-header2.component.css']
})
export class SectionHeader2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
